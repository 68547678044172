import { useEffect, useState } from 'react';

import { Content } from 'antd/lib/layout/layout';
import {
  Card, Checkbox, Col, Form, Modal, PageHeader, Row, Tag, Typography, Upload, UploadProps,
} from 'antd';
import { EditOutlined, PlusCircleTwoTone, InboxOutlined } from '@ant-design/icons';
import {
  getDocumentsList, addDocument, updateDocument, documentsDownload,
} from 'configs';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';
import ButtonAnt from 'components/ButtonAnt';
import { useForm } from 'antd/lib/form/Form';
import InputItem from 'components/forms/InputItem/InputItem';
import { SelectItem } from 'components/forms/InputItem/SelectItem';

const { Dragger } = Upload;

const Documents = () => {
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>(null);

  const { fetch: getDocs, requestResult: docs } = useRequest(getDocumentsList);
  const { fetch: getDoc, requestResult: doc } = useRequest(documentsDownload);
  const { fetch: setDoc, isLoading: isAddLoading, isSuccess, dropState: dsS } = useRequest(addDocument);
  const { fetch: editDocs, isLoading: isEditLoading, isSuccess: isSuccesEdit, dropState: dsSE } = useRequest(updateDocument);

  useMount(() => getDocs());

  const toogle = async (edited: any = null) => {
    await setEditId(edited);
    await form.resetFields();
    await setIsOpen(!isOpen);
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getDochandle = (id: number) => {
    getDoc({}, {}, `/${id}`);
  };

  const gohandle = () => {
    const data = form.getFieldsValue();
    if (!editId) {
      const formData = new FormData();
      formData.append('file', data.file[0].originFileObj);
      formData.append('info', JSON.stringify({
        documentType: data.documentType,
        fileNameEn: data.fileNameEn,
        fileNameRu: data.fileNameRu,
        // published: true,
        fileName: data.file[0].name,
      }));
      setDoc(formData);
    } else {
      editDocs({
        documentType: data.documentType,
        fileNameEn: data.fileNameEn,
        fileNameRu: data.fileNameRu,
        published: data.published,
      }, {}, `/${editId.id}`);
    }
  };

  useEffect(() => {
    if (isSuccesEdit || isSuccess) {
      toogle(null);
      form.resetFields();
      getDocs();
      isSuccess && dsS();
      isSuccesEdit && dsSE();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccesEdit, isSuccess]);

  const docTypes = [
    {
      label: 'Аудит',
      value: 'AUDIT',
    },
    {
      label: 'Юридический',
      value: 'LEGAL',
    },
  ];

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    // action: '',
    accept: 'application/pdf',
    openFileDialogOnClick: true,
  };

  useEffect(() => {
    if (!doc) return;
    const url = window.URL.createObjectURL(new Blob([doc]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'FileName.pdf');

    document.body.appendChild(link);

    link.click();

    link?.parentNode?.removeChild(link);
  }, [doc]);

  return (
    <>
      <Modal
        visible={isOpen}
        title={editId ? 'Редактировать документ' : 'Добавить документ'}
        style={{ top: '100px' }}
        destroyOnClose
        zIndex={901}
        footer={[
          <ButtonAnt
            key={0}
            onClick={() => {
              form.resetFields();
              toogle(null);
            }}
          >
            Отмена

          </ButtonAnt>,
          <ButtonAnt
            key={1}
            htmlType="submit"
            type="primary"
            form="form"
            onClick={gohandle}
            loading={isAddLoading || isEditLoading}
          >
            {editId ? 'Сохранить' : 'Создать'}

          </ButtonAnt>]}
        onCancel={() => toogle(true)}
      >
        <Form
          name="basic"
          id="form"
          form={form}
          autoComplete="off"
          initialValues={{ ...editId }}
          style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
        >
          <div className="signinForm_box">
            <InputItem
              label="Название документа РУ"
              name="fileNameRu"
              placeholder="Введите имя"
              maxLength={350}
              required
            />
            <InputItem
              label="Название документа EN"
              name="fileNameEn"
              placeholder="Введите имя"
              maxLength={350}
              required
            />
            <SelectItem
              label="Роль"
              options={docTypes}
              name="documentType"
              required
              placeholder="Выберите тип документа"
            />
            {!editId
              ? (
                <Form.Item name="file" getValueFromEvent={getFile}>
                  <Dragger {...props} customRequest={dummyRequest}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Нажмите или перетяните файл для загрузки</p>
                    <p className="ant-upload-hint">
                      Вы можете выбрать один документ PDF документ
                    </p>
                  </Dragger>
                </Form.Item>
              ) : null}
            <Form.Item
              name="published"
              valuePropName="checked"
            >
              <Checkbox defaultChecked>
                Опубликован
              </Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {' '}
      <PageHeader
        title="Документы"
      />
      <Content>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            {
              (docs || []).map((el: any) => (
                <Col span={8}>
                  <Card
                    style={{ width: 300, marginBottom: 20 }}
                    /*   cover={(
                      <Pdf width={50} height={50} style={{ marginTop: '30px' }} />
                    )} */
                    actions={[
                      <EditOutlined key="edit" onClick={() => toogle(el)} />,
                      <Tag color={el.published ? 'success' : 'error'}>{el.published ? 'Активный' : 'Не активный'}</Tag>,
                    ]}
                  >
                    <div className="accountData" style={{ height: '100px' }}>
                      <Typography.Text strong>{el.fileNameRu}</Typography.Text>
                      <span className="accountData_title">{docTypes.find((l: any) => el.documentType === l.value)?.label || ''}</span>
                      <span>{el.creationDate}</span>
                      <Typography.Link onClick={() => getDochandle(el.id)}>Просмотр</Typography.Link>
                    </div>
                  </Card>
                </Col>
              ))
            }
            <Card
              style={{ width: 300, marginBottom: 20 }}
              onClick={() => toogle(null)}
              cover={(
                <PlusCircleTwoTone style={{ marginTop: '70px', fontSize: '80px' }} />
                    )}
            />
          </Row>
        </div>
      </Content>
    </>
  );
};

export default Documents;
