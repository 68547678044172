import { FC } from 'react';

import { Pagination, Select } from 'antd';
import { observer } from 'mobx-react';

import { Box } from '../layout/Box';

const { Option } = Select;

type TableFooterProps = {
    currentPage?: number;
    perPage?: number;
    perPageChange: (perPage: number) => void;
    total?: number;
    paginationChange?: (page: number, perPage: number | undefined) => void;
}

export const TableFooter: FC<TableFooterProps> = ({
  perPage,
  currentPage,
  paginationChange,
  perPageChange,
  total,
}) => (
  <Box padding={[2, 0, 0, 0]} width="100%" direction="row" justify="space-between">
    <div>
      <span>{'На странице: '}</span>
      <Select value={perPage} onChange={perPageChange}>
        <Option value={10}>10</Option>
        <Option value={20}>20</Option>
        <Option value={30}>30</Option>
      </Select>
    </div>
    <Pagination
      total={total}
      current={currentPage}
      pageSize={perPage}
      onChange={paginationChange}
      showSizeChanger={false}
    />
  </Box>
);

export default observer(TableFooter);
