export default class LocalStorage {
  static set(key: string, data: any) {
    try {
      const value = JSON.stringify(data);
      localStorage.setItem(key, value);
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  static get(key: string) {
    try {
      const response = localStorage.getItem(key);
      return response ? JSON.parse(response) : null;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  static remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  static clear() {
    try {
      localStorage.clear();
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
}
