import { CSSProperties, forwardRef, PropsWithChildren } from 'react';

export type BoxProps = {
    padding?:
        | number
        | [number]
        | [number, number]
        | [number, number, number]
        | [number, number, number, number];
    margin?:
        | number
        | [number]
        | [number, number]
        | [number, number, number]
        | [number, number, number, number];
    direction?: CSSProperties['flexDirection'];
    align?: CSSProperties['alignItems'];
    justify?: CSSProperties['justifyContent'];
    flex?: CSSProperties['flex'];
    width?: CSSProperties['width'];
    height?: CSSProperties['height'];
    maxHeight?: CSSProperties['maxHeight'];
    minHeight?: CSSProperties['minHeight'];
    overflow?: CSSProperties['overflow'];
    position?: CSSProperties['position'];
    className?: string;
    dataTestID?: string;
    display?: CSSProperties['display'];
}

export function calcOffset(padding: number | number[]): string {
  const resultGrid = Array.isArray(padding) ? padding : [padding];
  const pad = resultGrid.map(i => i * 8);

  return `${pad.join('px ')}px`;
}

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
  (
    {
      padding = 0,
      margin = 0,
      direction = 'column',
      justify = 'normal',
      align = 'stretch',
      children,
      width,
      height,
      maxHeight,
      minHeight,
      flex,
      overflow,
      className,
      dataTestID,
      position,
      display = 'flex',
    },
    ref,
  ) => {
    const styles = {
      padding: calcOffset(padding),
      margin: calcOffset(margin),
      display,
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      width,
      height,
      maxHeight,
      minHeight,
      overflow,
      flex,
      position,
    };

    return (
      <div ref={ref} style={styles} className={className} data-testid={dataTestID}>
        {children}
      </div>
    );
  },
);
