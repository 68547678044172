import { useEffect, useState } from 'react';

import {
  Button, Checkbox, Form, Modal, PageHeader, Table, Tag, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import {
  UserAddOutlined, EditOutlined,
} from '@ant-design/icons';
import TableFooter from 'components/Table/TableFooter';
import useRequest from 'hooks/useRequest';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';
import { SelectItem } from 'components/forms/InputItem/SelectItem';
import {
  employeesEdit, employeesGet, employeesSet, getRolesCfg,
} from 'configs';
import { useMount } from 'hooks';
import { emailRule } from 'components/forms/InputItem/rules';

function ucFirst(str: string) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

const Employees = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [filters, setFilter] = useState<any>({});
  const [editEmployee, setEditEmployee] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { fetch, isLoading, requestResult } = useRequest(employeesGet);
  const {
    fetch: getRoles, requestResult: rolesList,
  } = useRequest(getRolesCfg);

  const roles = rolesList ? rolesList.map((el: any) => ({ label: el.name, value: el.id, ...el })) : [];

  const {
    fetch: setEmployees, isSuccess: isSuccessCreate, isLoading: isLoadingSet, errorMessage, dropState: ds,
  } = useRequest(employeesSet);

  const {
    fetch: edEmployee, isSuccess: isSuccessEdit, isLoading: isLoadingEdit, dropState,
  } = useRequest(employeesEdit);

  useMount(() => {
    fetch({}, { perPage, page: 0 });
    getRoles();
  });

  useEffect(() => {
    fetch({}, {
      perPage,
      page: page - 1,
      ...(Object.keys(filters).length && { ...filters }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch]);

  useEffect(() => {
    if (page === 1) {
      fetch({}, { perPage, page: 0, ...(Object.keys(filters).length && { ...filters }) });
    }
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const toogle = (reset: boolean = false) => {
    dropState();
    ds();
    if (reset) form.resetFields();
    if (editEmployee && isOpen) setEditEmployee(false);
    setIsOpen(!isOpen);
  };

  const editEmploeyee = (data: any) => {
    const b = {
      email: data.email,
      firstName: data.firstName,
      isActive: data.isActive,
      lastName: data.lastName,
      roles: data.roles[0].id,
      socialRef: data.socialRef,
      id: data.id,
    };
    setEditEmployee(true);
    form.setFieldsValue(b);
    toogle();
  };

  const setFilters = (values: any) => {
    const cleanList: any = {};
    for (const key in values) {
      if (values[key] && values[key] !== 'ALL') {
        cleanList[key] = values[key];
      }
    }
    setFilter(cleanList);
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetch({}, { perPage, page: 0, ...filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const list = errorMessage.data?.validationErrors?.filter((el: any) => el.message === '105') || [];

  const createEmployees = () => {
    const values = form.getFieldsValue();
    setEmployees({
      email: values.email,
      firstName: values.firstName,
      isActive: values.isActive,
      lastName: values.lastName,
      roles: [rolesList.find((el: any) => el.id === values.roles)],
      socialRef: 'empty',
    });
  };

  const setEditEmployees = () => {
    const values = form.getFieldsValue();
    edEmployee({
      firstName: values.firstName,
      isActive: values.isActive,
      lastName: values.lastName,
      roles: [rolesList.find((el: any) => el.id === values.roles)],
      socialRef: values.socialRef,
    }, {}, `/${values.id}`);
  };

  useEffect(() => {
    if (isSuccessEdit) {
      toogle(true);
      fetch({}, {
        perPage,
        page: page - 1,
        ...(Object.keys(filters).length && { ...filters }),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEdit]);

  useEffect(() => {
    if (isSuccessCreate) {
      toogle(true);
      fetch({}, {
        perPage,
        page: page - 1,
        ...(Object.keys(filters).length && { ...filters }),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate]);

  const last = 'Последние';
  const days = 'дней';

  const daysOptions = [
    {
      label: `${last} 7 ${days}`,
      value: 7,
    },
    {
      label: `${last} 30 ${days}`,
      value: 30,
    },
    {
      label: `${last} 90 ${days}`,
      value: 90,
    },
  ];

  const COLUMN = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 30,
      minWidth: 30,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 170,
      minWidth: 170,
    },
    {
      title: 'Имя',
      dataIndex: 'lastName',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Фамилия',
      dataIndex: 'firstName',
      width: 130,
      minWidth: 130,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'creationDate',
    },
    {
      title: 'Роль',
      dataIndex: 'roles',
      render: (row: any) => (row[0].name),
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      render: (row: any) => <Tag color={row ? 'success' : 'error'}>{row ? 'Активный' : 'Неактивный'}</Tag>,
    },
    {
      title: 'Редактировать',
      dataIndex: '',
      render: (_: any, rows: any) => <span onClick={() => editEmploeyee(rows)} style={{ cursor: 'pointer' }}><EditOutlined /></span>,
    },
  ];

  return (
    <>
      <Modal
        visible={isOpen}
        title={editEmployee ? 'Редактировать сотрудника' : 'Добавить сотрудника'}
        style={{ top: '100px' }}
        zIndex={901}
        footer={[
          <ButtonAnt key={0} onClick={() => toogle(true)}>Отмена</ButtonAnt>,
          <ButtonAnt
            key={1}
            htmlType="submit"
            type="primary"
            form="form"
            onClick={editEmployee ? setEditEmployees : createEmployees}
            loading={isLoadingSet || isLoadingEdit}
          >
            {editEmployee ? 'Сохранить' : 'Создать'}

          </ButtonAnt>]}
        onCancel={() => toogle(true)}
      >
        <Form
          name="basic"
          id="form"
          form={form}
          autoComplete="off"
          initialValues={{ isActive: false }}
          style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
        >
          <div className="signinForm_box">
            {editEmployee && (
            <InputItem
              label="ID"
              name="id"
              // placeholder="Введите имя"
              maxLength={250}
              required
              disabled
            />
            )}
            <InputItem
              label="Имя"
              name="lastName"
              placeholder="Введите имя"
              maxLength={250}
              required
            />
            <InputItem
              label="Фамилия"
              name="firstName"
              placeholder="Введите фамилию"
              maxLength={250}
              required
            />
            <InputItem
              label="Почта"
              name="email"
              placeholder="Введите почту"
              maxLength={250}
              disabled={editEmployee}
              rules={[emailRule]}
              required
            />
            <SelectItem
              label="Роль"
              options={roles}
              name="roles"
              required
              placeholder="Выберите роль"
            />
            <Form.Item
              name="isActive"
              valuePropName="checked"
            >
              <Checkbox defaultChecked={false}>
                Активный
              </Checkbox>
            </Form.Item>
            {list.length ? <Typography.Text type="danger">Такой email уже существует</Typography.Text> : null}
          </div>

        </Form>
      </Modal>
      <PageHeader
        title="Сотрудники"
        extra={[
          <Button key="1" type="primary" icon={<UserAddOutlined />} onClick={() => setIsOpen(true)}>Добавить сотрудника</Button>,
        ]}
      />
      <Content>
        <div className="inpage centred" style={{ display: 'flex', minWidth: '1128px' }}>
          <Form
            className="filters"
            form={form}
            name="filter"
            onFinish={setFilters}
            id="search"
            autoComplete="off"
          >
            <InputItem
              label="Фамилия, имя или почта"
              name="keyword"
              required={false}
              placeholder="Введите фамилию, имя или почту"
              uneditable={false}
              maxLength={250}
            />
            <SelectItem
              label="Роль"
              options={roles}
              name="role"
              placeholder="Выберите роль"
            />
            <SelectItem
              label="Статус пользователя"
              options={[
                { value: 'false', label: 'Неактивный' },
                { value: 'true', label: 'Активный' },
              ]}
              name="status"
              placeholder="Выберите статус"
            />
            <SelectItem
              label={ucFirst(days)}
              options={daysOptions}
              name="days"
              placeholder="Создан"
            />
            <div className="filters_butt">
              <ButtonAnt
                htmlType="submit"
                type="primary"
                form="search"
              >
                Выбрать
              </ButtonAnt>
              <ButtonAnt
                onClick={() => {
                  form.resetFields();
                  fetch({}, { perPage, page: 0 });
                  setFilters({});
                }}
              >
                Сбросить
              </ButtonAnt>
            </div>
          </Form>
        </div>
        <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ minWidth: '1128px' }} size="small" loading={isLoading} pagination={false} />
        {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}

      </Content>

    </>
  );
};

export default Employees;
