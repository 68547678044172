import { ReactNode } from 'react';

import { Rule } from 'antd/lib/form';
import { checkPasswords } from 'utils/checkFields';

export type { Rule } from 'antd/lib/form';

export const NOT_EMPTY = {
  pattern: /^(?!\s*$).+/,
  message: 'Обязательное для заполнения поле',
};

export const NO_RULE: Rule = {};

export const REQUIRED: Rule = {
  required: true,
  message: 'Обязательное для заполнения поле',
};

const emailRegex = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export const emailRule = {
  pattern: emailRegex,
  message: 'Указан некорректный адрес электронной почты',
};

export const LICENSE_RULE = {
  pattern: /\d{3}-\d{5}-\d{6}/,
  message: 'Указана некорректная лицензия',
};

export const INIPA_RULE = {
  pattern: /\d{3}-\d{3}-\d{3}\s\d{2}/,
  message: 'Указан некорректный снилс',
};

export const PASSPORT_RULE = {
  pattern: /\d{2}\s\d{2}\s\d{6}/,
  message: 'Указан некорректный пасспорт',
};

export const NUMBER_RULE = {
  pattern: /^\d*$/,
  message: 'Допустимы только цифры',
};

export function createMinLengthRule(label: ReactNode, min?: number) {
  return min !== undefined
    ? {
      pattern: new RegExp(`^[0-9]{${min}}$`),
      message: `${label || ''} должен состоять из ${min} цифр`,
    }
    : {};
}

export function telephoneInputCheck(placeholder: string): Rule {
  return {
    pattern: new RegExp(`^((?!${placeholder}).)*$`),
    message: 'Указан некорректный номер телефона',
  };
}
export function dateInputCheck(placeholder: string): Rule {
  return {
    pattern: new RegExp(`^((?!${placeholder}).)*$`),
    message: 'Указана некорректная дата',
  };
}

export function goodPassword(): Rule {
  return {
    validator: (_, value) => (
      checkPasswords(value).rating > 3
        ? Promise.resolve()
        : Promise.reject(new Error('Слишком простой пароль'))),
  };
}

export function checkAddress(): Rule {
  return {
    validator: (_, value) => (
      value?.match(/^0x[0-9a-fA-F]{40}$/)
        ? Promise.resolve()
        : Promise.reject(new Error('Некорректный адрес'))),
  };
}

export function confimPassword(pass: string): Rule {
  return {
    validator: (_, value) => (
      value === pass
        ? Promise.resolve()
        : Promise.reject(new Error('Введенные пароли не совпадают'))),
  };
}

export function checkMinMax(min: number, max: number, curr = 'RUB'): Rule {
  return {
    validator: (_, value) => (
      value >= min && value <= max
        ? Promise.resolve()
        : Promise.reject(new Error(`Сумма должна быть в пределах от ${min} ${curr} до ${max} ${curr}`))),
  };
}
/*
export function uniue(deponentsList: any[]): Rule {
  return {
    validator: (_, value) => (!deponentsList.map(deponent => deponent.Ogrn).includes(value)
      ? Promise.resolve()
      : Promise.reject(new Error(`Депонент с ОГРН ${value} уже существует`))),
  };
} */
