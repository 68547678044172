import { useEffect, useRef } from 'react';

import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import store from 'store/mainStore';
import Auth from 'pages/Auth';
import './assets/main.scss';
import PrivateRoute from 'components/PrivateRoute';
import 'antd/dist/antd.css';
// import LocalStorage from 'utils/LocalStorage';
// import Curtain from 'Curtain';
import Hub from 'pages/Hub';

const App = () => {
  const {
    isAuth, screenWidth, setScreenWidth,
  } = store;
  const wrapperRef = useRef<undefined | any>();

  useEffect(() => {
    const resize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setScreenWidth]);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    wrapperRef.current.style.width = `${screenWidth - 30}px`;
  }, [screenWidth]);

  // if (LocalStorage.get('curtainHash') !== 'AooEbhkQ2bxiLgxi42Wuc3pL85FC1AVNhjU2CJP1nF4HqWsiMhCJoQnnE4HDxV9n3Vpev4kaJbDawcg4nnZyB6C4FDqgJchme6r8' || !process.env.REACT_APP_PROD) return <Curtain />;

  return (
    <>
      <Switch>
        {/* <Route path="/verification/" component={IdentyVerification} /> */}
        <Route path="/signin">{isAuth ? <Redirect to="/dashboard/orders" /> : <Auth />}</Route>
        <PrivateRoute path="/dashboard/:id" auth={isAuth} isAccess><Hub /></PrivateRoute>
        <PrivateRoute path="/dashboard/" auth={isAuth} isAccess>
          {isAuth ? <Redirect to="/dashboard/orders" /> : <Redirect to="/signin" />}
        </PrivateRoute>
        <Route exact path="/">
          {isAuth ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />}
        </Route>
        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>

    </>
  );
};

export default observer(App);
