/* eslint-disable max-len */
import { observer } from 'mobx-react-lite';

type Props = {
    legal?: boolean;
    getData?: Function;
    uneditable?: boolean;
    outData: any;
}

const AccountData = ({ outData }: Props) => {
  const data: any = outData || {};

  const BASE_FIELDS = [
    {
      type: 'email',
      label: 'Почта',
      fieldName: 'email',
    },
    {
      type: 'phone',
      label: 'Телефон',
      fieldName: 'phone',
    },
    {
      label: 'Фамилия',
      fieldName: 'lastName',
    },
    {
      label: 'Имя',
      fieldName: 'firstName',
    },
    {
      label: 'Отчество',
      fieldName: 'middleName',
    },
  ];

  const AGREE_LIST = [
    {
      label: 'Согласие на рассылку',
      fieldName: 'isAgreeReceiveNotification',
    },
    {
      label: 'Согласие c условиями',
      fieldName: 'isAgreeTermsService',
    },
    {
      label: 'Согласие c политикой конфиденциальности',
      fieldName: 'isAgreePrivacyPolicy',
    },
  ];

  const LEGAL_LIST = [
    {
      label: 'Юридическое лицо',
      fieldName: 'legalType',
    },
    {
      label: 'Наименование юр. лица',
      fieldName: 'legalName',
    },
    {
      type: 'tin',
      label: 'ИНН',
      fieldName: 'legalTin',
    },
    {
      type: 'cor',
      label: 'КПП',
      fieldName: 'legalCOR',
    },
    {
      type: 'psrn',
      label: 'ОГРН',
      fieldName: 'legalPSRN',
    },
  ];

  const PHYSICAL_LIST = [
    {
      type: 'date',
      label: 'Дата рождения',
      fieldName: 'birthDate',
    },
    {
      type: 'inipa',
      label: 'СНИЛС',
      fieldName: 'inipa',
    },
    {
      type: 'tin',
      label: 'ИНН',
      fieldName: 'tin',
    },
    {
      label: 'Кем выдан паспорт',
      fieldName: 'passportIssuedBy',
    },
    {
      type: 'date',
      label: 'Дата выдачи паспорта',
      fieldName: 'passportIssuedDate',
    },
  ];

  return (
    <div className="inpage" style={{ justifyContent: 'flex-start' }}>
      {
        BASE_FIELDS.map((el: any) => (
          <div className="accountData " key={el.fieldName}>
            <span className="accountData_title">{el.label}</span>
            <span>{data[el.fieldName]}</span>
          </div>
        ))
      }
      {data.legalData
        ? (
          <>
            {
              LEGAL_LIST.map((el: any) => (
                <div className="accountData" key={el.fieldName}>
                  <span className="accountData_title">{el.label}</span>
                  <span>{data.legalData[el.fieldName]}</span>
                </div>
              ))
              }
            <div className="accountData">
              <span className="accountData_title">Юридический адрес</span>
              <span>{data.legalData.legalAddress}</span>
            </div>
            <div className="accountData">
              <span className="accountData_title">Фактический адрес</span>
              <span>{data.address}</span>
            </div>
          </>
        )
        : (
          <>
            {
              PHYSICAL_LIST.map((el: any) => (
                <div className="accountData" key={el.fieldName}>
                  <span className="accountData_title">{el.label}</span>
                  <span>{data.individualData[el.fieldName]}</span>
                </div>
              ))
              }
            <div className="accountData">
              <span className="accountData_title">Серия и номер паспорта</span>
              <span>{`${data.individualData.passportSeries} ${data.individualData.passportNumber}`}</span>
            </div>
            <div className="accountData">
              <span className="accountData_title">Юридический адрес</span>
              <span>{data.address}</span>
            </div>
          </>
        )}
      {
        AGREE_LIST.map((el: any) => (
          <div className="accountData" key={el.fieldName}>
            <span className="accountData_title">{el.label}</span>
            <span>{data[el.fieldName] ? 'Да' : 'Нет'}</span>
          </div>
        ))
      }
    </div>
  );
};
export default observer(AccountData);
