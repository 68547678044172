import { Collapse, Spin } from 'antd';
import { useMount } from 'hooks';
import useRequest from 'hooks/useRequest';
import { accountGet } from 'configs';

const { Panel } = Collapse;

type Props = {
  id: number;
}

const BankAccounts = ({ id } : Props) => {
  const {
    fetch, requestResult, isSuccess, isLoading,
  } = useRequest(accountGet);

  const onChange = () => {
    // console.log(key);
  };

  useMount(() => {
    fetch({}, {}, `/${id}`);
  });

  if (isLoading || !isSuccess) return <><Spin /></>;

  return (
    <>
      <Collapse
        defaultActiveKey={['1']}
        onChange={onChange}
        expandIconPosition="start"
      >
        {(requestResult || []).map((el: any) => (
          <Panel header={`${el.accountNick} ${el.isDefault ? '(По умолчанию)' : ''}`} key={el.accountNick}>
            <div className="accBox_line">
              <span>
                Навзание банка
              </span>
              <span>
                {el.bankName}
              </span>
            </div>
            <div className="accBox_line">
              <span>
                Банк БИК
              </span>
              <span>
                {el.bankBIC}
              </span>
            </div>
            <div className="accBox_line">
              <span>
                Номер счета
              </span>
              <span>
                {el.bankAccount}
              </span>
            </div>
          </Panel>
        ))}
      </Collapse>

    </>
  );
};
export default BankAccounts;
