import { ReactElement } from 'react';

import { Button, ButtonProps } from 'antd';

import './style.scss';

type IButtonProps = ButtonProps & {
  large?: boolean;
}

export const largeStyle = {
  height: '50px',
  fontWeight: 600,
  width: '100%',
};

const addInline = (i: boolean) => (i ? { ...largeStyle } : {});

const ButtonAnt = ({
  large,
  ...rest
}: IButtonProps): ReactElement => (
  <Button
    style={addInline(!!large)}
    {...rest}
  />
);

export default ButtonAnt;
