import { useEffect, useState } from 'react';

import {
  Card, Input, InputProps, Select, SelectProps, Tag,
} from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import { copyClipBoard, getStatusOrder, stateOptions } from 'pages/OrdersTable';
import { fixAddresLength } from 'utils';
import {
  CopyOutlined,
} from '@ant-design/icons';
import { transactionPatch } from 'configs';
import useRequest from 'hooks/useRequest';
import CopyToClipboard from 'components/CopyToClipboard';

type Props = {
    transaction: any;
    orderType: string;
    updateWhenChange: any;
    prevTransState?: string;
    disableEdit: boolean;
    isExpired: boolean;
}

const CryptoTransaction = ({
  transaction, orderType, updateWhenChange, prevTransState, disableEdit, isExpired,
}: Props) => {
  const {
    fetch, isSuccess, isLoading,
  } = useRequest(transactionPatch);

  const isPrevReady = prevTransState === 'DONE';

  const [values, setValues] = useState<any>({
    transactionNote: transaction?.transNotes,
    transState: transaction?.transState,
    txHash: transaction?.txId,
    orderType,
    transType: transaction?.transactionType,
    amount: transaction?.amount,
  });

  const updateTransaction = () => {
    const clearValues: any = {
      orderType,
      transType: transaction?.transactionType,
    };
    // TODO REFACTOR THIS SHIT!
    if (transaction.transState !== values.transState) clearValues.transState = values.transState;
    if (transaction.txId !== values.txHash) clearValues.txHash = values.txHash;
    if (transaction.amount !== values.amount) clearValues.amount = values.amount;
    if (transaction.transNotes !== values.transactionNote) clearValues.transactionNote = values.transactionNote;
    fetch(clearValues, {}, `/${transaction?.transactionId}`);
  };

  const statusHandler = (value: SelectProps) => {
    setValues({ ...values, transState: value });
  };

  const noteHandler = (value: InputProps) => {
    setValues({ ...values, transactionNote: value });
  };

  const txHandler = (value: InputProps) => {
    setValues({ ...values, txHash: value });
  };

  useEffect(() => {
    if (!isSuccess) return;
    updateWhenChange();
  }, [isSuccess, updateWhenChange]);

  return (
    <Card
      title={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Крипто транзакция (
          {transaction.currency}
          )
          {getStatusOrder(transaction.transState)}
        </div>
      )}
    >
      <div className="inpage">
        <div className="accountData flexRow">
          <span className="accountData_title">Номер транзакции</span>
          <span>{transaction.transactionNumber}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Дата создания</span>
          <span>{transaction.createDate}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Изменено</span>
          <span>{transaction.updateDate || '---'}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Сотрудник</span>
          <span>{transaction.employeeName || '---'}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Сумма</span>
          <span><Input value={transaction.amount} style={{ textAlign: 'end', border: 'none', width: '200px' }} disabled /></span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Адрес пользователя</span>
          <span>
            <a href={`https://bscscan.com/address/${transaction.userWallet}`} target="_blank" rel="noreferrer">{fixAddresLength(transaction.userWallet)}</a>
            <CopyToClipboard data={transaction.userWallet} marginLeft="5px" />
          </span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">TxID</span>
          <span>
            {transaction.txId
              ? (
                <div style={{ display: 'flex', justifyContent: 'space-between', gridGap: '10px', alignItems: 'center' }}>
                  <a href={`https://bscscan.com/tx/${transaction.txId}`} target="_blank" rel="noreferrer">{fixAddresLength(transaction.txId)}</a>
                  <CopyOutlined onClick={() => copyClipBoard(transaction.txId)} style={{ color: '#1890ff' }} />
                </div>
              )
              : (
                <Input
                  onChange={(e: any) => txHandler(e.target.value)}
                  value={values.txHash}
                  style={{ textAlign: 'end', border: 'none', width: '200px' }}
                  disabled={!isPrevReady || disableEdit || isExpired}
                />
              )}

          </span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Имя сети</span>
          <span>{transaction.networkName}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Статус платежа</span>
          {values.transState === 'DONE' ? (<Tag color="success">DONE</Tag>)
            : values.transState === 'EXPIRED' ? (<Tag color="error">EXPIRED</Tag>)
              : (
                <Select
                  options={stateOptions.filter((el: any) => el.value === 'INPROGRESS' || el.value === 'PENDING')}
                  placeholder=""
                  onChange={statusHandler}
                  disabled={['DONE', 'REJECTED', 'EXPIRED', 'INPROGRESS'].includes(transaction.transState) || !isPrevReady || disableEdit || isExpired}
                  value={values.transState}
                  style={{ width: '200px', textAlign: 'end' }}
                />
              )}
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Примечание транзакции</span>
          <Input.TextArea placeholder="" onChange={(e: any) => noteHandler(e.target.value)} value={values.transactionNote} style={{ width: '200px' }} disabled={disableEdit} />
        </div>
        {!disableEdit && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <ButtonAnt loading={isLoading} type="primary" onClick={updateTransaction}>Сохранить</ButtonAnt>
        </div>
        )}
      </div>
    </Card>
  );
};

export default CryptoTransaction;
