export const BASE_LINK = !process.env.REACT_APP_BY_LOCALHOST
  ? process.env.REACT_APP_PROD
    ? 'https://backoffice.liquid.holdings/'
    : 'https://hub-backoffice-dev.liquid.holdings/'
  : 'http://localhost:8180/';
export const API_VERSION = 'backoffice/api/';

export const BINANCE_RPC = 'https://bsc-dataseed.binance.org/';

/* export const TOKEN_ADDRESS = '0x174Fa651D7b64a21a5BF9d87c3FA801760a87fE3';

export const CONTRACT_ADDRESS = '0x34Cd80d6309b4377d1552298FE715615C3560e30';
export const CONTRACT_ABI = '';

export const infinitySize = 1.157920892373162e+71; */

export enum RequestTypes {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch',
  }

export enum ApiLinks {
    signin = 'authentication/sign-in',
    emp = 'employees',
    roles = 'employees/roles',
    users = 'users',
    verification = 'verification',
    getOrderList = 'orders',
    setUserNote = 'users/note',
    accounts = 'user/accounts',
    changeActive = 'users',
    transactions = 'orders/transaction',
    getCompanyData = 'company/data',
    paymentService= 'tools/payment',
    supportedNetworks ='tools/network',
    fees = 'tools/fee',
    companyAccounts = 'tools/accounts',
    documents = 'documents',
    documentsDownload = 'documents/download',
    codeChekUrlChanhge='authentication/code/password/change',
    codeChekUrlReset='authentication/code/password/reset',
    changePassword='authorization/password/change',
    changePasswordReset='authorization/password/reset',
}

export interface IRequestConfig {
    link: '' | ApiLinks;
    method: '' | RequestTypes;
    tag?: string;
    media?: boolean;
}

// reset passw

export const getCodeReset: IRequestConfig = {
  link: ApiLinks.codeChekUrlReset,
  method: RequestTypes.patch,
};

export const codeSendReset: IRequestConfig = {
  link: ApiLinks.codeChekUrlReset,
  method: RequestTypes.post,
};

export const changePasswordReset: IRequestConfig = {
  link: ApiLinks.changePasswordReset,
  method: RequestTypes.put,
};

// reset passw

// CAHNGE PASS
export const getCodeCFG: IRequestConfig = {
  link: ApiLinks.codeChekUrlChanhge,
  method: RequestTypes.patch,
};

export const codeSend: IRequestConfig = {
  link: ApiLinks.codeChekUrlChanhge,
  method: RequestTypes.post,
};

export const changePassword: IRequestConfig = {
  link: ApiLinks.changePassword,
  method: RequestTypes.put,
};
// CHANGE PASS

export const getDocumentsList: IRequestConfig = {
  link: ApiLinks.documents,
  method: RequestTypes.get,
};

export const documentsDownload: IRequestConfig = {
  link: ApiLinks.documentsDownload,
  method: RequestTypes.get,
};

export const addDocument: IRequestConfig = {
  link: ApiLinks.documents,
  method: RequestTypes.post,
  media: true,
};

export const updateDocument: IRequestConfig = {
  link: ApiLinks.documents,
  method: RequestTypes.put,
};

export const setUserNote: IRequestConfig = {
  link: ApiLinks.setUserNote,
  method: RequestTypes.patch,
};

export const activeChange: IRequestConfig = {
  link: ApiLinks.changeActive,
  method: RequestTypes.patch,
};

export const accountGet: IRequestConfig = {
  link: ApiLinks.accounts,
  method: RequestTypes.get,
};

export const companyDataGet: IRequestConfig = {
  link: ApiLinks.getCompanyData,
  method: RequestTypes.get,
};

export const updateFee: IRequestConfig = {
  link: ApiLinks.fees,
  method: RequestTypes.put,
};

export const geteFee: IRequestConfig = {
  link: ApiLinks.fees,
  method: RequestTypes.get,
};

export const transactionPatch: IRequestConfig = {
  link: ApiLinks.transactions,
  method: RequestTypes.patch,
};

export const signin: IRequestConfig = {
  link: ApiLinks.signin,
  method: RequestTypes.post,
};

export const verificationUser: IRequestConfig = {
  link: ApiLinks.verification,
  method: RequestTypes.patch,
};

export const getOrders: IRequestConfig = {
  link: ApiLinks.getOrderList,
  method: RequestTypes.get,
};

export const patchOrders: IRequestConfig = {
  link: ApiLinks.getOrderList,
  method: RequestTypes.patch,
};

export const usersGet: IRequestConfig = {
  link: ApiLinks.users,
  method: RequestTypes.get,
};

export const employeesGet: IRequestConfig = {
  link: ApiLinks.emp,
  method: RequestTypes.get,
};

export const employeesSet: IRequestConfig = {
  link: ApiLinks.emp,
  method: RequestTypes.post,
};

export const employeesEdit: IRequestConfig = {
  link: ApiLinks.emp,
  method: RequestTypes.put,
};

export const getRolesCfg: IRequestConfig = {
  link: ApiLinks.roles,
  method: RequestTypes.get,
};

export const paymentServiceGet: IRequestConfig = {
  link: ApiLinks.paymentService,
  method: RequestTypes.get,
};

export const paymentServiceSet: IRequestConfig = {
  link: ApiLinks.paymentService,
  method: RequestTypes.post,
};

export const paymentServiceEdit: IRequestConfig = {
  link: ApiLinks.paymentService,
  method: RequestTypes.patch,
};

export const supportedNetworksGet: IRequestConfig = {
  link: ApiLinks.supportedNetworks,
  method: RequestTypes.get,
};

export const supportedNetworksSet: IRequestConfig = {
  link: ApiLinks.supportedNetworks,
  method: RequestTypes.post,
};

export const supportedNetworksEdit: IRequestConfig = {
  link: ApiLinks.supportedNetworks,
  method: RequestTypes.patch,
};

export const caGet: IRequestConfig = {
  link: ApiLinks.companyAccounts,
  method: RequestTypes.get,
};

export const caSet: IRequestConfig = {
  link: ApiLinks.companyAccounts,
  method: RequestTypes.post,
};

export const caEdit: IRequestConfig = {
  link: ApiLinks.companyAccounts,
  method: RequestTypes.put,
};
