import { useState } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Avatar } from 'assets/images/avatarDefault.svg';
import {
  Popover,
} from 'antd';

import './style.scss';
import ChangePassword from './ChangePassword';

const Header = () => {
  const {
    isAuth, userData, logout,
  } = store;

  const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  // const toogleModal = () => setIsOpen(!isOpen);
  const toogleModalPassword = () => setIsOpenChangePassword(!isOpenChangePassword);

  /* const clickOpen = () => {
    toogleModal();
    setVisible(false);
  }; */

  const clickOpenPassword = () => {
    toogleModalPassword();
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <div className="container">
      <div className="bgImage" />
      <div className="header">
        <div className="header_menu">
          {isAuth && <ChangePassword isOpen={isOpenChangePassword} toogleModal={toogleModalPassword} />}
          { isAuth && (
          <Popover
            visible={visible}
            trigger="click"
            content={(
              <div className="accountMenu">
                <span className="accountMenu_name">{userData.userName}</span>
                {/* <span onClick={clickOpen}>Сменить пароль</span> */}
                <span onClick={clickOpenPassword}>Сменить пароль</span>
                <div className="divider" />
                <span onClick={logout}>Выйти</span>
              </div>
)}
            onVisibleChange={handleVisibleChange}
          >
            <Avatar onClick={() => setVisible(!visible)} style={{ cursor: 'pointer' }} />
          </Popover>
          )}
        </div>

      </div>

    </div>
  );
};
export default observer(Header);
