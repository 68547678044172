import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import useRequest from 'hooks/useRequest';
import { getOrders } from 'configs';
import { useMount } from 'hooks';
import {
  Form, Table, message, PageHeader, Tag, Typography,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { fixAddresLength } from 'utils';
import { TableFooter } from 'components/Table/TableFooter';
import { SelectItem } from 'components/forms/InputItem/SelectItem';
import InputItem from 'components/forms/InputItem/InputItem';
import ButtonAnt from 'components/ButtonAnt';
import { Content } from 'antd/lib/layout/layout';
import OrderModal from 'components/modals/orderModal/OrderModal';

import { days, daysOptions } from './Users';
import AccountModal from './AccountModal';

function ucFirst(str: string) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export const copyClipBoard = (str: string) => {
  navigator.clipboard.writeText(str);
  message.success({
    content: 'Скопировано в буфер обмена',
    duration: 3,
    icon: <CopyOutlined style={{ color: '#2DAFE7' }} />,
  });
};
type Props = {
    initFilters?: any;
    disableHeader?: boolean;
    zindex?: number;
}

export const stateOptions = [
  {
    label: 'Done',
    value: 'DONE',
  },
  {
    label: 'In progress',
    value: 'INPROGRESS',
  },
  {
    label: 'Expired',
    value: 'EXPIRED',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

export const getStatusOrder = (status: string) => {
  switch (status) {
    case 'NEW':
      return (
        <Tag color="processing">
          {ucFirst(status)}
        </Tag>
      );
    case 'DONE':
      return (
        <Tag color="success">
          {ucFirst(status)}
        </Tag>
      );
    case 'INPROGRESS':
    case 'PENDING':
      return (
        <Tag color="warning">
          {ucFirst(status)}
        </Tag>
      );
    case 'REJECTED':
    case 'EXPIRED':
      return (
        <Tag color="error">
          {ucFirst(status)}
        </Tag>
      );
    default:
      break;
  }
};

const OrderTable = ({ initFilters, disableHeader } : Props) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [filters, setFilter] = useState<any>(initFilters || {});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<number>(0);
  const [order, setOrder] = useState<number>(0);
  const [isOpenOrder, setIsOpenOrder] = useState<boolean>(false);
  const { fetch, isLoading, requestResult } = useRequest(getOrders);

  const toogle = (id: number = 0) => {
    if (!isOpen) {
      setUser(id);
      setIsOpen(true);
    } else {
      setUser(0);
      setIsOpen(false);
    }
  };

  const toogleOrder = (id: number = 0) => {
    if (!isOpenOrder) {
      setOrder(id);
      setIsOpenOrder(true);
    } else {
      setOrder(0);
      setIsOpenOrder(false);
      fetch({}, {
        perPage,
        page: page - 1,
        ...(Object.keys(filters).length && { ...filters }),
      });
    }
  };

  const [form] = Form.useForm();

  useMount(() => fetch({}, { perPage, page: 0, ...(Object.keys(filters).length && { ...filters }) }));

  useEffect(() => {
    fetch({}, {
      perPage,
      page: page - 1,
      ...(Object.keys(filters).length && { ...filters }),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch]);

  useEffect(() => {
    if (page === 1) {
      fetch({}, { perPage, page: 0, ...(Object.keys(filters).length && { ...filters }) });
    }
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const setFilters = (values: any) => {
    const cleanList: any = { ...initFilters } || {};
    for (const key in values) {
      if (values[key]) {
        cleanList[key] = values[key];
      }
    }
    setFilter(cleanList);
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetch({}, { perPage, page: 0, ...filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // onst optionsNetworks = networks.map((el: any) => ({ label: el.networkName, value: el.id, ...el }));

  const typeOptions = [
    {
      label: 'Погашать',
      value: 'REDEEM',
    },
    {
      label: 'Приобретать',
      value: 'ACQUIRING',
    },
  ];

  const stateFiltred = initFilters?.state ? stateOptions.filter((el: any) => (initFilters?.state.split(',')).includes(el.value)) : stateOptions;

  const getAddr = (ntw: string) => {
    switch (ntw) {
      case 'BINANCE(BSC)':
        return 'bscscan.com';
      case 'ETHEREUM':
        return 'etherscan.io';
      default:
        return '';
    }
  };

  const COLUMN = [
    {
      title: 'Номер заказа',
      dataIndex: 'orderNumber',
      width: 100,
      minWidth: 100,
      render: (row: any, rows: any) => <Typography.Link onClick={() => toogleOrder(rows?.orderId)}>{row}</Typography.Link>,
    },
    {
      title: 'Номер пользователя',
      dataIndex: 'memberNumber',
      width: 100,
      minWidth: 100,
      ...(!disableHeader && { render: (row: any, rows: any) => <Typography.Link onClick={() => toogle(rows?.userId)}>{row}</Typography.Link> }),
    },
    {
      title: 'Дата заказа',
      dataIndex: 'dateTime',
      width: 170,
      minWidth: 170,
    },
    {
      title: 'Тип',
      dataIndex: 'orderType',
      width: 100,
      minWidth: 100,
      render: (row: any) => <>{row === 'REDEEM' ? 'Погашать' : 'Приобретать'}</>,
    },
    {
      title: 'Количество',
      dataIndex: 'amount',
      width: 130,
      minWidth: 130,
      // TODO ADD VALUE
      render: (row: any) => (row || 0),
    },
    {
      title: 'Сеть',
      dataIndex: 'networkName',
      width: 100,
      minWidth: 100,
      render: (row: any) => row?.[0] || 'unknow',
    },
    {
      // TODO SWITCH LINK EXP
      title: 'Адрес',
      dataIndex: 'address',
      render: (row: any, all: any) => (row?.[0] ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://${getAddr(all.networkName?.[0])}/address/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row?.[0])}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: 'TxID',
      dataIndex: 'txId',
      render: (row: any, all: any) => (row?.[0] ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://${getAddr(all.networkName?.[0])}/tx/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row?.[0])}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: 'Срок оплаты',
      dataIndex: 'expirationDate',
      render: (row: any) => row || '---',
    },
    {
      title: 'Mетод оплаты',
      dataIndex: 'paymentMethodEnum',
    },
    {
      title: 'Состояние',
      dataIndex: 'state',
      render: (row: any) => getStatusOrder(row),
    },
  ];

  return (
    <>
      {!disableHeader && (
      <PageHeader
        title="Заказы"
      />
      )}
      <Content>
        <OrderModal id={order} isOpen={isOpenOrder} toogleModal={toogleOrder} zindex={900} />
        <AccountModal id={user} isOpen={isOpen} toogleModal={toogle} zindex={200} />
        <div className="inpage centred" style={{ display: 'flex', width: '100%' }}>
          <Form
            className="filters"
            form={form}
            name="basic"
            onFinish={setFilters}
            id="searchOrder"
            autoComplete="off"
          >
            <InputItem
              label="Заказ"
              name="keyword"
              required={false}
              placeholder="Номер заказа"
              uneditable={false}
              maxLength={250}
            />
            <InputItem
              label="Номер пользователя"
              name="userNumber"
              required={false}
              placeholder="Номер пользователя"
              uneditable={false}
              maxLength={250}
            />
            <SelectItem
              label="Тип"
              options={typeOptions}
              name="type"
              placeholder="Тип"
            />
            <SelectItem
              label="Состояние"
              options={stateFiltred.map((el: any) => ({ ...el, label: ucFirst(el.label) }))}
              name="state"
              placeholder="Состояние"
            />
            <SelectItem
              label={ucFirst(days)}
              options={daysOptions}
              name="days"
              placeholder={ucFirst(days)}
            />
            {/*  <SelectItem
          label={order_network}
          options={optionsNetworks.map((el: any) => ({ ...el, label: ucFirst(el.label) }))}
          name="network"
          placeholder={order_network}
        /> */}
            <div className="filters_butt">
              <ButtonAnt
                htmlType="submit"
                type="primary"
                form="searchOrder"
              >
                Применить
              </ButtonAnt>
              <ButtonAnt
                onClick={() => {
                  form.resetFields();
                  fetch({}, { perPage, page: 0, ...initFilters });
                  setFilters(initFilters || {});
                }}
              >
                Сбросить
              </ButtonAnt>
            </div>
          </Form>
          <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ width: '100%' }} size="small" loading={isLoading} pagination={false} />
          {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}
        </div>
      </Content>
    </>
  );
};

export default observer(OrderTable);
