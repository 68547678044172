import React, { useState } from 'react';

import {
  MenuProps, Layout, Menu,
} from 'antd';
import {
  UserOutlined, TeamOutlined, FileTextOutlined, AuditOutlined,
} from '@ant-design/icons';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoMin } from 'assets/images/logo_min.svg';
import Header from 'components/header/Header';
import {
  NavLink, Switch, useParams,
} from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import store from 'store/mainStore';
import { observer } from 'mobx-react';

import Employees from './Employees';
import Users from './Users';
import OrdersTable from './OrdersTable';
import OrderTracking from './OrderTracking';
import VerificationTracking from './VerificationTracking';
import Limits from './LImits';
import PaymentService from './PaymentService';
import BankAccounts from './BankAccounts';
import Documents from './Documents';
import SupportedNetworks from './SupportedNetworks';

const { Content, Sider } = Layout;
const minHeigth = window.innerHeight;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
/* const items = [
  getItem('Документы и финансы', 'docs', <FileTextOutlined />, [
    getItem(
      <NavLink to="legaldocs">
        Документы
      </NavLink>,
      'legaldocs',
      <AuditOutlined />,
    ),
  ]),
  getItem('Инструменты', 'tools', <FileTextOutlined />, [
    getItem(
      <NavLink to="limits">
        Комиссии и лимиты
      </NavLink>,
      'limits',
    ),
    getItem(
      <NavLink to="paymentservices">
        Платежные сервисы
      </NavLink>,
      'paymentservices',
    ),
    getItem(
      <NavLink to="bankAccounts">
        Банковские аккаунты
      </NavLink>,
      'bankAccounts',
    ),
    getItem(
      <NavLink to="networks">
        Поддерживаемые сети
      </NavLink>,
      'networks',
    ),
  ]),
]; */
const Hub: React.FC = () => {
  const { isAuth, isAccess } = store;
  const params: any = useParams();
  const [collapsed, setCollapsed] = useState(false);

  const createMenu = () => {
    const list = [];
    isAccess('user:view') && list.push(getItem('Пользователи', 'usersSubs', <UserOutlined />, [
      getItem(
        <NavLink to="users">
          Список пользователей
        </NavLink>,
        'users',
      ),
      getItem(
        <NavLink to="verification">
          Верификация
        </NavLink>,
        'verification',
      ),
    ]));
    isAccess('order:view') && list.push(getItem('Заказы', 'ordersSubs', <FileTextOutlined />, [
      getItem(
        <NavLink to="orders">
          Список заказов
        </NavLink>,
        'orders',
      ),
      getItem(
        <NavLink to="tracking">
          Отслеживание заказов
        </NavLink>,
        'tracking',
      ),
    ]));
    isAccess('employee:view') && list.push(getItem(
      <NavLink to="/dashboard/employees">
        Сотрудники
      </NavLink>,
      'employees',
      <TeamOutlined />,
    ));
    isAccess('tools:view') && list.push(
      getItem('Инструменты', 'tools', <FileTextOutlined />, [
        getItem(
          <NavLink to="limits">
            Комиссии и лимиты
          </NavLink>,
          'limits',
        ),
        getItem(
          <NavLink to="paymentservices">
            Платежные сервисы
          </NavLink>,
          'paymentservices',
        ),
        getItem(
          <NavLink to="bankAccounts">
            Банковские аккаунты
          </NavLink>,
          'bankAccounts',
        ),
        getItem(
          <NavLink to="networks">
            Поддерживаемые сети
          </NavLink>,
          'networks',
        ),
      ]),
    );
    isAccess('audit:view') && list.push(
      getItem('Документы и финансы', 'docs', <FileTextOutlined />, [
        getItem(
          <NavLink to="legaldocs">
            Документы
          </NavLink>,
          'legaldocs',
          <AuditOutlined />,
        ),
      ]),
    );
    return list;
  };

  return (
    <Layout style={{ background: 'transparent', minHeight: minHeigth }}>
      <Sider
        style={{ background: 'transparent', borderRight: '1px solid rgb(240, 240, 240)' }}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        width={collapsed ? '' : '260px'}
        theme="light"
      >
        <div className="logoSideBar">
          {collapsed ? <LogoMin /> : <Logo />}
        </div>
        {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '10px',
              position: 'absolute',
              marginLeft: '260px',
              zIndex: '999',
              background: '#2DAFE7',
            }}
          >
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed),
            })}
          </div> */}
        <Menu
          style={{ minHeight: '99%', width: collapsed ? '' : '260px' }}
          defaultSelectedKeys={['orders']}
          defaultOpenKeys={['usersSubs', 'ordersSubs']}
          mode="inline"
          theme="light"
          selectedKeys={[params.id]}
          items={createMenu()}
        />
      </Sider>
      <Layout style={{ background: 'transparent' }}>
        <Header />
        <Content style={{ padding: '0px 20px' }}>
          <Switch>
            <PrivateRoute path="/dashboard/employees" auth={isAuth} isAccess={isAccess('employee:view')}><Employees /></PrivateRoute>
            <PrivateRoute path="/dashboard/users" auth={isAuth} isAccess><Users /></PrivateRoute>
            <PrivateRoute path="/dashboard/orders" auth={isAuth} isAccess><OrdersTable /></PrivateRoute>
            <PrivateRoute path="/dashboard/tracking" auth={isAuth} isAccess><OrderTracking /></PrivateRoute>
            <PrivateRoute path="/dashboard/verification" auth={isAuth} isAccess><VerificationTracking /></PrivateRoute>
            <PrivateRoute path="/dashboard/limits" auth={isAuth} isAccess><Limits /></PrivateRoute>
            <PrivateRoute path="/dashboard/paymentservices" auth={isAuth} isAccess><PaymentService /></PrivateRoute>
            <PrivateRoute path="/dashboard/networks" auth={isAuth} isAccess><SupportedNetworks /></PrivateRoute>
            <PrivateRoute path="/dashboard/bankAccounts" auth={isAuth} isAccess><BankAccounts /></PrivateRoute>
            <PrivateRoute path="/dashboard/legaldocs" auth={isAuth} isAccess><Documents /></PrivateRoute>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default observer(Hub);
