// TODO: make enum for all cases
export const checkPasswords = (str: string) => {
  const password = str;
  const s_letters = 'qwertyuiopasdfghjklzxcvbnm';
  const b_letters = 'QWERTYUIOPLKJHGFDSAZXCVBNM';
  const digits = '0123456789';
  const specials = '!@#$%^&*()_-+=/.,:;[]{}|';
  let is_s = false;
  let is_b = false;
  let is_d = false;
  let is_sp = false;
  const is_long = str.length >= 8;
  for (let i = 0; i < password.length; i++) {
    if (!is_s && s_letters.indexOf(password[i]) !== -1) is_s = true;
    else if (!is_b && b_letters.indexOf(password[i]) !== -1) is_b = true;
    else if (!is_d && digits.indexOf(password[i]) !== -1) is_d = true;
    else if (!is_sp && specials.indexOf(password[i]) !== -1) is_sp = true;
  }
  let rating = 0;
  let text = '';
  if (is_s) rating++;
  if (is_b) rating++;
  if (is_d) rating++;
  if (is_sp) rating++;
  if (password.length < 6 && rating < 3) text = 'Простой';
  else if (password.length < 6 && rating >= 3) text = 'Средний';
  else if (password.length >= 8 && rating < 3) text = 'Средний';
  else if (password.length >= 8 && rating >= 3) text = 'Сложный';
  else if (password.length >= 6 && rating === 1) text = 'Простой';
  else if (password.length >= 6 && rating > 1 && rating < 4) text = 'Средний';
  else if (password.length >= 6 && rating === 4) text = 'Сложный';

  return {
    rating, text, is_s, is_b, is_d, is_sp, is_long,
  };
};

const validator = (name: string, value: any): boolean => {
  switch (name) {
    case 'address':
      return value?.match(/^0x[0-9a-fA-F]{40}$/) || false;
    case 'amount':
    case 'paymentMethodEnum':
    case 'oldPassword':
      return typeof value === 'string';
    case 'networkId':
      return typeof value === 'number';
    case 'newPassword':
    case 'confirmNewPassword':
      return value ? checkPasswords(value).rating > 3 : false;
    default:
      return false;
  }
};

const checkFields = (obj: any, reqF?: any) => {
  let fraudPoint: number = 0;
  if (reqF) {
    const res = reqF.map((item: any) => obj[item]);
    if (res.includes(undefined)) return false;
  }

  if (!Object.keys(obj).length) return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !validator(key, obj[key])) fraudPoint += 1;
  }
  return fraudPoint === 0;
};

export default checkFields;
