import { useCallback, useState } from 'react';

import {
  API_VERSION, BASE_LINK, IRequestConfig,
} from 'configs';
import Axios from 'axios';
import LocalStorage from 'utils/LocalStorage';
import store from 'store/mainStore';
import { message } from 'antd';
import { getErr, getSimpleErr } from 'utils';
// import LocalStorage from 'utils/LocalStorage';

const timeout = 30000;

interface ErrorRequest {
    isError: boolean;
    errorMessage: any;
}

interface IJWT{
    exp: number;
    iss: string;
    jti: string;
    l: string;
    n: string;
    r: string;
    sub: string;
}

const parseJwt = (token: string) : null | IJWT => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const checkExpire = (exp: number) : boolean => ((exp - 10) < Math.floor(Date.now() / 1000));

/* const updToken = async () => {

}; */

export default function useRequest({ link, method, media = false } : IRequestConfig) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorRequest>({ isError: false, errorMessage: '' });
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [requestResult, setRequestResult] = useState<any>();
  const [metaData, setMetaData] = useState<any>({});
  const { updateToken, logout } = store;

  const { isError, errorMessage } = error;

  const createParams = (obj: any): string => {
    if (obj) {
      let compiledParams = '?';

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          compiledParams += `${key}=${obj[key]}&`;
        }
      }
      compiledParams = compiledParams.slice(0, -1);
      return compiledParams;
    }
    return '';
  };

  const createRequest = async ({
    token, body, params, semiLInk, semiMethod, addTolink,
  }: any): Promise<any> => {
    let result: object = {};
    const headers = {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-type': media ? 'multipart/form-data' : 'application/json',
      Accept: media ? 'multipart/form-data' : 'application/json',
    };
    const axiosInstance = Axios.create({
      timeout,
      headers,
    });
    const fullLink = `${BASE_LINK}${API_VERSION}${semiLInk || link}${addTolink || ''}${createParams(params)}`;

    switch (semiMethod || method) {
      case 'get':
        result = axiosInstance.get(fullLink);
        break;
      case 'post':
        result = axiosInstance.post(fullLink, body);
        break;
      case 'put':
        result = axiosInstance.put(fullLink, body);
        break;
      case 'patch':
        result = axiosInstance.patch(fullLink, body);
        break;
      case 'delete':
        result = axiosInstance.delete(fullLink, { data: body });
        break;
      default:
        break;
    }
    return result;
  };

  const interceprot401 = async ({ body, params, addTolink }: any): Promise<any> => {
    const refresh = LocalStorage.get('r');
    const token = LocalStorage.get('a') || false;
    const requstData = {
      ...(token && { token }),
      body,
      params,
      addTolink,
    };
    // here refresh if need

    try {
      const result = await createRequest(requstData);
      setIsSuccess(true);
      setRequestResult(result.data);
      setIsLoading(false);
    } catch (err:any) {
      if (!err.response) return;
      if (err.response.data.errorCode === 129) {
        setError({
          isError: true,
          errorMessage: err.response.data.message,
        });
        setIsLoading(false);
      }
      if (err.response.data.httpStatus === 400) {
        setError({
          isError: true,
          errorMessage: err.response.data.message,
        });
        setIsLoading(false);
        getErr(err.response.data.validationErrors);
      }
      if (err.response.data.httpStatus === 409) {
        setError({
          isError: true,
          errorMessage: err.response.data.message,
        });
        setIsLoading(false);
        getSimpleErr(err.response.data);
      }
      if (err.response.status === 401) {
        if (err.response.config.url.includes('sign-in')) {
          message.error('Не верные учетные данные');
        }
        const expRefresh = parseJwt(refresh)?.exp || 0;
        const isExpired = checkExpire(expRefresh);
        if (!isExpired) {
          try {
            const result = await createRequest({
              token: refresh,
              semiLInk: 'authorization/refresh-token',
              semiMethod: 'get',
            });
            if (!result) return;
            updateToken(result.data.accessToken, 'access');
            interceprot401({ body, params, addTolink });
          } catch (e: any) {
            // eslint-disable-next-line no-console
            logout();
          }
        } else {
          logout();
        }
      } else {
        setError({
          isError: true,
          errorMessage: err.response || 'Empty error data',
        });
        setIsLoading(false);
      }
    }
  };

  const dropState = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setError({ isError: false, errorMessage: '' });
    setRequestResult('');
  };

  const fetch = useCallback(
    (body = {}, params = {}, addTolink = '') => {
      setMetaData({
        body,
        params,
        link,
        addTolink,
      });
      setError({ isError: false, errorMessage: '' });
      setIsLoading(true);
      interceprot401({ body, params, addTolink });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  return {
    isLoading, isError, errorMessage, isSuccess, fetch, requestResult, dropState, error, metaData,
  };
}
