import { useState, useEffect } from 'react';

import { EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import {
  Table, PageHeader, Tag, Checkbox, Form, Modal, Button,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';
import { paymentServiceEdit, paymentServiceGet, paymentServiceSet } from 'configs';
import useRequest from 'hooks/useRequest';
import { useForm } from 'antd/lib/form/Form';
import TableFooter from 'components/Table/TableFooter';
import { useMount } from 'hooks';

const PaymentServices = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, edit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const [form] = useForm();
  const {
    fetch, isLoading, requestResult,
  } = useRequest(paymentServiceGet);

  const {
    fetch: setFetch, isSuccess: isSuccessSet, isLoading: isLoadingSet, dropState: dsSet,
  } = useRequest(paymentServiceSet);

  const {
    fetch: editFetch, isSuccess: isSuccessEdit, isLoading: isLoadingEdit, dropState: dsEdit,
  } = useRequest(paymentServiceEdit);

  useMount(() => { fetch(); });

  const toogle = (reset: boolean = false) => {
    dsEdit();
    dsSet();
    if (reset) form.resetFields();
    if (isEdit && isOpen) edit(false);
    setIsOpen(!isOpen);
  };

  const setEditData = (data: any) => {
    edit(true);
    form.setFieldsValue(data);
    toogle();
  };

  const createaymentProvider = () => {
    const values = form.getFieldsValue();
    setFetch({
      ...values,
    });
  };

  const editPaymentProvider = () => {
    const values = form.getFieldsValue();
    editFetch({
      ...values,
    }, {}, `/${values.id}`);
  };

  useEffect(() => {
    if (isSuccessEdit || isSuccessSet) {
      toogle(true);
      fetch({}, {
        perPage,
        page: page - 1,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEdit, isSuccessSet]);

  const COLUMN = [
    {
      title: 'Провайдер',
      dataIndex: 'providerName',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Ссылка',
      dataIndex: 'providerUrl',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Нижний порог',
      dataIndex: 'amountLowerBound',
      width: 170,
      minWidth: 170,
    },
    {
      title: 'Верхний порог',
      dataIndex: 'amountUpperBound',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Заметка',
      dataIndex: 'note',
      width: 130,
      minWidth: 130,
      // TODO ADD VALUE
      render: (row: any) => (row || '---'),
    },
    {
      title: 'Статус',
      dataIndex: 'active',
      width: 100,
      minWidth: 100,
      render: (row: any) => <Tag color={row ? 'success' : 'error'}>{row ? 'Активный' : 'Не активный'}</Tag>,
    },
    {
      title: ' ',
      dataIndex: 'active',
      width: 50,
      minWidth: 50,
      render: (_: any, rows: any) => <span onClick={() => setEditData(rows)} style={{ cursor: 'pointer' }}><EditOutlined /></span>,
    },
  ];

  return (
    <>
      <Modal
        visible={isOpen}
        title={isEdit ? 'Редактировать провайдера' : 'Добавить провайдера'}
        style={{ top: '100px' }}
        zIndex={901}
        footer={[
          <ButtonAnt key={0} onClick={() => toogle(true)}>Отмена</ButtonAnt>,
          <ButtonAnt
            key={1}
            htmlType="submit"
            type="primary"
            form="form"
            onClick={isEdit ? editPaymentProvider : createaymentProvider}
            loading={isLoadingSet || isLoadingEdit}
          >
            {isEdit ? 'Сохранить' : 'Создать'}

          </ButtonAnt>]}
        onCancel={() => toogle(true)}
      >
        <Form
          name="basic"
          id="form"
          form={form}
          autoComplete="off"
          initialValues={{ active: false }}
          style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
        >
          <div className="signinForm_box">
            {isEdit && (
            <InputItem
              label="id"
              name="id"
          // placeholder="Введите имя"
              maxLength={250}
              required
              disabled
            />
            )}
            <InputItem
              label="Имя"
              name="providerName"
              placeholder="Введите имя"
              maxLength={250}
              required
            />
            <InputItem
              label="Ссылка на провайдера"
              name="providerUrl"
              placeholder="Введите ссылку"
              maxLength={250}
              required
            />
            <InputItem
              label="Минимальная сумма"
              name="amountLowerBound"
              placeholder="Введите сумму"
              maxLength={250}
              required
            />
            <InputItem
              label="Максимальная сумма"
              name="amountUpperBound"
              placeholder="Введите сумму"
              maxLength={250}
              required
            />
            <InputItem
              label="Комментарий"
              name="note"
              placeholder="Введите комментарий"
              maxLength={500}
              multiline
            />
            <Form.Item
              name="active"
              valuePropName="checked"
            >
              <Checkbox defaultChecked={false}>
                Активный
              </Checkbox>
            </Form.Item>
          </div>

        </Form>
      </Modal>
      <PageHeader
        title="Платежные сервисы"
        extra={[
          <Button key="1" type="primary" icon={<FileAddOutlined />} onClick={() => setIsOpen(true)}>Добавить сервис</Button>,
        ]}
      />
      <Content>
        <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ width: '100%' }} size="small" loading={isLoading} pagination={false} />
        {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}
      </Content>
    </>
  );
};

export default observer(PaymentServices);
