import { useEffect, useState } from 'react';

import {
  Form, PageHeader, Table, Tag, Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import TableFooter from 'components/Table/TableFooter';
import useRequest from 'hooks/useRequest';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';
import { SelectItem } from 'components/forms/InputItem/SelectItem';
import {
  usersGet,
} from 'configs';
import { useMount } from 'hooks';
import { observer } from 'mobx-react';

import AccountModal, { getStatusAccount } from './AccountModal';

function ucFirst(str: string) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export const last = 'Последние';
export const days = 'дней';

export const daysOptions = [
  {
    label: `${last} 7 ${days}`,
    value: 7,
  },
  {
    label: `${last} 30 ${days}`,
    value: 30,
  },
  {
    label: `${last} 90 ${days}`,
    value: 90,
  },
];

type Props = {
  initFilters?: any;
  title?: string;
}
export const verifyStatus = [
  {
    label: 'In progress',
    value: 'INPROGRESS',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Verified',
    value: 'VERIFIED',
  },
];

const Users = ({ initFilters, title } : Props) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [filters, setFilter] = useState<any>(initFilters || {});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<number>(0);
  const {
    fetch, requestResult, isLoading,
  } = useRequest(usersGet);

  const toogle = (id: number = 0) => {
    if (!isOpen) {
      setUser(id);
      setIsOpen(true);
    } else {
      setUser(0);
      setIsOpen(false);
      fetch({}, {
        perPage,
        page: page - 1,
        ...(Object.keys(filters).length && { ...filters }),
      });
    }
  };
  const [form] = Form.useForm();

  const verifyStatusFiltred = initFilters?.verifyState ? verifyStatus.filter((el: any) => (initFilters?.verifyState.split(',')).includes(el.value)) : verifyStatus;

  useMount(() => {
    fetch({}, { perPage, page: 0, ...initFilters });
  });

  useEffect(() => {
    fetch({}, {
      perPage,
      page: page - 1,
      ...(Object.keys(filters).length && { ...filters }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch]);

  useEffect(() => {
    if (page === 1) {
      fetch({}, { perPage, page: 0, ...(Object.keys(filters).length && { ...filters }) });
    }
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const setFilters = (values: any) => {
    const cleanList: any = { ...initFilters } || {};
    for (const key in values) {
      if (values[key] && values[key] !== 'ALL') {
        cleanList[key] = values[key];
      }
    }
    setFilter(cleanList);
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetch({}, { perPage, page: 0, ...filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const verifyDays = [
    {
      label: `${last} 7 ${days}`,
      value: 7,
    },
    {
      label: `${last} 30 ${days}`,
      value: 30,
    },
    {
      label: `${last} 90 ${days}`,
      value: 90,
    },
  ];

  const COLUMN = [
    {
      title: 'ID',
      dataIndex: 'userNumber',
      width: 100,
      minWidth: 100,
      render: (row: any, rows: any) => <Typography.Link onClick={() => toogle(rows?.id)}>{row}</Typography.Link>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 170,
      minWidth: 170,
      render: (row: any, rows: any) => <Typography.Link onClick={() => toogle(rows?.id)}>{row}</Typography.Link>,
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      width: 130,
      minWidth: 130,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'creationDate',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
    },
    {
      title: 'Тип',
      dataIndex: 'userType',
      render: (row: any) => <span>{row === 'USER_PHYSICAL' ? 'Физ. лицо' : 'Юр. лицо'}</span>,
    },
    {
      title: 'Верификация',
      dataIndex: 'verification',
      render: (row: any) => getStatusAccount(row.verificationState, true),
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      render: (row: any) => <Tag color={row ? 'success' : 'error'}>{row ? 'Активный' : 'Неактивный'}</Tag>,
    },
  ];

  return (
    <>
      <PageHeader
        title={title || 'Пользователи'}
      />
      <Content>
        <AccountModal id={user} isOpen={isOpen} toogleModal={toogle} />
        <div className="inpage centred" style={{ display: 'flex', minWidth: '1128px' }}>
          <Form
            className="filters"
            form={form}
            name="basic"
            onFinish={setFilters}
            id="search"
            autoComplete="off"
          >
            <InputItem
              label="Фамилия, имя, id или почта"
              name="keyword"
              required={false}
              placeholder="Введите фамилию, имя или почту"
              uneditable={false}
              maxLength={250}
            />
            {/*   <SelectItem
              label="Роль"
              options={roles}
              name="role"
              placeholder="Выберите роль"
            /> */}
            <SelectItem
              label="Статус пользователя"
              options={[
                { value: 'false', label: 'Неактивный' },
                { value: 'true', label: 'Активный' },
              ]}
              name="active"
              placeholder="Выберите статус"
            />
            <SelectItem
              label={ucFirst(days)}
              options={daysOptions}
              name="days"
              placeholder="Создан"
            />
            <SelectItem
              label="Дней верификации"
              options={verifyDays}
              name="verifyDays"
              placeholder="Верифицирован"
            />
            <SelectItem
              label="Статус верификации"
              options={verifyStatusFiltred}
              name="verifyState"
              mode="multiple"
              placeholder="Статус верификации"
            />
            <div className="filters_butt">
              <ButtonAnt
                htmlType="submit"
                type="primary"
                form="search"
              >
                Выбрать
              </ButtonAnt>
              <ButtonAnt
                onClick={() => {
                  form.resetFields();
                  fetch({}, { perPage, page: 0, ...initFilters });
                  setFilters({});
                }}
              >
                Сбросить
              </ButtonAnt>
            </div>
          </Form>
        </div>
        <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ minWidth: '1128px' }} size="small" loading={isLoading} pagination={false} />
        {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}

      </Content>

    </>
  );
};

export default observer(Users);
