import { useEffect, useState } from 'react';

import { Content } from 'antd/lib/layout/layout';
import {
  Card, Col, Form, Modal, PageHeader, Row,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { companyDataGet, updateFee } from 'configs';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';
import { useForm } from 'antd/lib/form/Form';
import { checkMinMax } from 'components/forms/InputItem/rules';

const Limits = () => {
  const { fetch, isLoading, requestResult } = useRequest(companyDataGet);
  const { fetch: updateFeeFetch, isSuccess, isLoading: isUpdated, dropState } = useRequest(updateFee);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();

  useMount(() => fetch());

  const edithandler = (element: any) => {
    form.setFieldsValue(element);
    setIsOpen(true);
  };

  const toogle = (reset: boolean = false) => {
    dropState();
    if (reset) form.resetFields();
    setIsOpen(!isOpen);
  };

  const editFee = () => {
    const body = {
      ...form.getFieldsValue(),
      active: true,
    };
    delete body.id;
    updateFeeFetch(body, {}, `/${form.getFieldValue('id')}`);
  };

  useEffect(() => {
    if (isSuccess) {
      toogle(true);
      fetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <PageHeader
        title="Комиссии и лимиты"
      />
      <Modal
        visible={isOpen}
        title="Редактировать комиссию"
        style={{ top: '50px' }}
        destroyOnClose
        zIndex={901}
        footer={[
          <ButtonAnt key={0} onClick={() => toogle(true)}>Отмена</ButtonAnt>,
          <ButtonAnt
            key={1}
            htmlType="submit"
            type="primary"
            form="formed"
            onClick={editFee}
            loading={isUpdated}
          >
            Сохранить

          </ButtonAnt>]}
        onCancel={() => toogle(true)}
      >
        <Form
          name="basic"
          id="formed"
          form={form}
          initialValues={{ active: true }}
          style={{
            width: '100%', display: 'flex', marginBottom: '0px', flexDirection: 'row', flexWrap: 'wrap',
          }}
        >
          <div className="formContainer">
            <InputItem
              label="id"
              name="id"
              maxLength={250}
              required
              disabled
            />
            <InputItem
              label="Название комиссии"
              name="feeName"
              placeholder="Введите адрес"
              maxLength={250}
              required
            />
            <InputItem
              label="Тип ордера"
              name="orderType"
              placeholder="Введите адрес"
              maxLength={250}
              required
            />
            <InputItem
              label="Процент"
              name="percentage"
              placeholder="Введите адрес"
              maxLength={250}
              rules={[checkMinMax(0, 100, '%')]}
              required
            />
            <InputItem
              label="Комментарий"
              name="note"
              placeholder="Введите комментарий"
              maxLength={500}
              multiline
            />

          </div>

        </Form>
      </Modal>
      <Content>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            {/* <Col span={8}>
              <Card
                title="Лимиты обмена"
                extra={<EditOutlined key="edit" />}
                loading={isLoading}
              >
                <div className="accountData flexRow">
                  <span className="accountData_title">Минимальная сумма обмена:</span>
                  <span />
                </div>
                <div className="accountData flexRow">
                  <span className="accountData_title">Платежный сервис</span>
                  <span>10,000 RUB</span>
                </div>
                <div className="accountData flexRow">
                  <span className="accountData_title">Банковский перевод</span>
                  <span>10,000 RUB</span>
                </div>
                <div className="accountData flexRow">
                  <span className="accountData_title">Максимальная сумма обмена:</span>
                  <span />
                </div>
                <div className="accountData flexRow">
                  <span className="accountData_title">Платежный сервис</span>
                  <span>150,000 RUB</span>
                </div>
                <div className="accountData flexRow">
                  <span className="accountData_title">Банковский перевод</span>
                  <span>5,000,000 RUB</span>
                </div>
              </Card>
            </Col> */}
            <Col span={10}>
              <Card
                title="Комиссии"
                loading={isLoading}
              >
                {
                  (requestResult?.lhFees || []).map((el: any) => (
                    <div className="accountData flexRow">
                      <span className="accountData_title">
                        {el.feeName}
                        <EditOutlined key="edit" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => edithandler(el)} />
                      </span>
                      <span>{`${el.percentage}%`}</span>

                    </div>
                  ))
                }

              </Card>
            </Col>
            <Col span={10}>
              <Card
                title="Сроки платежа"
                loading={isLoading}
                extra={<EditOutlined key="edit" />}
              >
                <div className="accountData flexRow">
                  <span className="accountData_title">Срок ожидания платежа</span>
                  <span>10 дней</span>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </>
  );
};

export default Limits;
