/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import {
  Modal, Skeleton, Col, Row, Typography, Input, Collapse,
} from 'antd';
import {
  DoubleRightOutlined,
} from '@ant-design/icons';
import useRequest from 'hooks/useRequest';
import { getOrders, patchOrders } from 'configs';
import ButtonAnt from 'components/ButtonAnt';
import store from 'store/mainStore';

import AccountModal from '../../../pages/AccountModal';
import { getStatusOrder } from '../../../pages/OrdersTable';
import CryptoTransaction from './CryptoTransaction';
import FiatTransaction from './FiatTransaction';

const { Panel } = Collapse;

type Props = {
    isOpen: boolean;
    toogleModal: any;
    id: number;
    zindex?: number;
}

const OrderModal = ({ isOpen, toogleModal, id, zindex = 100 }: Props) => {
  const { isAccess } = store;

  const {
    fetch, requestResult: orderData, isSuccess, dropState, isLoading,
  } = useRequest(getOrders);

  const {
    fetch: fetchPatchOrder, isSuccess: PatchSuccess, isLoading: patchLoading,
  } = useRequest(patchOrders);

  const [isOpenUser, setIsOpenUser] = useState<boolean>(false);
  const [user, setUser] = useState<number>(0);
  const [comment, setComment] = useState<string | null>(null);

  const toogle = (ids: number = 0) => {
    if (!isOpen) {
      setUser(ids);
      setIsOpenUser(true);
    } else {
      setUser(0);
      setIsOpenUser(false);
    }
  };

  const getOrder = () => {
    if (!id || id === 0) return;
    fetch({}, {}, `/${id}`);
  };

  const updateNote = () => {
    fetchPatchOrder({ note: comment }, {}, `/${id}`);
  };

  useEffect(() => {
    !isOpen && orderData && dropState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!id) return;
    getOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isOpen && !isSuccess) { getOrder(); }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setComment(null);
    getOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatchSuccess]);

  const TitleGenerate = () => (
    <div className="accountModal_title">
      {isLoading ? <div className="accountModal_name">Загрузка...</div>
        : (
          <>
            <div className="accountModal_name">
              <span className="accountModal_nameModal">
                ID заказа:
              </span>
              <span className="accountModal_nameAcc">
                {orderData?.orderNumber}
              </span>
            </div>
            <div style={{ marginLeft: '20px' }}>
              {getStatusOrder(orderData.stateEnum)}
            </div>
          </>
        )}
    </div>
  );

  const getTransactionData = (name: string) => {
    if (!orderData || !isSuccess) return {};
    return orderData.transactions?.find((el: any) => el.transactionType === name) || {};
  };
  const isAcquire = orderData?.orderType === 'ACQUIRING';
  const isReedeem = orderData?.orderType === 'REDEEM';
  const transactionUser = getTransactionData(isAcquire ? 'FIAT_PAYMENT' : 'CRYPTO_REEDEM'); // 'FIAT_PAYMENT' : el.transactionType === 'CRYPTO_ACQUIRE')) : {};
  const transactionOut = getTransactionData(isAcquire ? 'CRYPTO_ACQUIRE' : 'FIAT_PAYMENT'); // isSuccess ? orderData?.transactions?.find((el: any) => (orderData?.orderType === 'REDEEM' ? el.transactionType === 'FIAT_PAYMENT' : el.transactionType === 'CRYPTO_ACQUIRE')) : {};

  return (
    <>
      <AccountModal id={user} isOpen={isOpenUser} toogleModal={toogle} zindex={200} />

      <Modal
        visible={isOpen}
        destroyOnClose
        title={<TitleGenerate />}
        footer={false}
        style={{ top: '10px', minHeight: '90vh', width: '90vw' }}
        width="90%"
        zIndex={zindex}
        onCancel={toogleModal}
        bodyStyle={{ minHeight: '90vh', width: '90vw' }}
      >
        {!isSuccess || isLoading || !orderData ? <Skeleton />
          : (
            <>
              <div className="inpage" style={{ backgroundColor: '#e6f7ff', border: '1px solid #bae7ff', borderRadius: '5px', padding: '10px', justifyContent: 'flex-start' }}>
                <div className="accountData w25p">
                  <span className="accountData_title">Номер заказа</span>
                  <span>{orderData.orderNumber}</span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Сборы</span>
                  <span>{`${orderData.fee}%`}</span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Номер пользователя</span>
                  <span><Typography.Link onClick={() => toogle(orderData.userNumber)}>{orderData.userNumber}</Typography.Link></span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Тип заказа</span>
                  <span>{orderData.orderType === 'ACQUIRING' ? 'Приобретать' : 'Погашать'}</span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Дата создания заказа</span>
                  <span>{orderData.createDate}</span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Дата последнего обновления</span>
                  <span>{orderData.updateDate}</span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Cрок оплаты</span>
                  <span>{orderData.dueDate}</span>
                </div>
                <div className="accountData w25p">
                  <span className="accountData_title">Согласие на договор займа</span>
                  <span>{orderData.consentLoanAgreement ? 'Есть' : 'Нет'}</span>
                </div>
              </div>
              <div className="mt20" />
              <Collapse
                bordered={false}
                defaultActiveKey={orderData.orderNotes ? ['1'] : []}
                className="site-collapse-custom-collapse"
              >
                <Panel header="Комментарий к заказу" key="1" className="site-collapse-custom-panel">
                  <Input.TextArea placeholder="" onChange={(e: any) => setComment(e.target.value)} defaultValue={orderData.orderNotes} value={comment || orderData.orderNotes} style={{ width: '100%' }} />
                  <div className="mt20" />
                  <ButtonAnt loading={patchLoading} type="primary" onClick={updateNote} disabled={!orderData.orderNotes && !comment}>Сохранить</ButtonAnt>
                </Panel>
              </Collapse>
              <div className="mt20" />
              <Row>
                <Col span={11}>
                  {isAcquire
                    ? <FiatTransaction disableEdit={!isAccess('order:edit')} transaction={transactionUser} orderType={orderData.orderType} updateWhenChange={getOrder} prevTransState="DONE" isExpired={orderData.stateEnum === 'EXPIRED'} />
                    : <CryptoTransaction disableEdit={!isAccess('order:edit') || ['NEW'].includes(transactionUser.transState)} transaction={transactionUser} orderType={orderData.orderType} updateWhenChange={getOrder} prevTransState="DONE" isExpired={orderData.stateEnum === 'EXPIRED'} />}

                </Col>
                <Col span={2} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}><DoubleRightOutlined /></Col>
                <Col span={11}>
                  {isReedeem
                    ? (
                      <FiatTransaction
                        transaction={transactionOut}
                        orderType={orderData.orderType}
                        updateWhenChange={getOrder}
                        prevTransState={transactionUser.transState}
                        disableAmonut
                        isExpired={orderData.stateEnum === 'EXPIRED'}
                        disableEdit={!isAccess('order:edit')}
                      />
                    )
                    : (
                      <CryptoTransaction
                        transaction={transactionOut}
                        orderType={orderData.orderType}
                        updateWhenChange={getOrder}
                        prevTransState={transactionUser.transState}
                        disableEdit={!isAccess('order:edit')}
                        isExpired={orderData.stateEnum === 'EXPIRED'}
                      />
                    )}
                </Col>
              </Row>

            </>

          )}
      </Modal>
    </>

  );
};

export default OrderModal;
