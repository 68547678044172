import { CopyOutlined } from '@ant-design/icons';
import { copyClipBoard } from 'pages/OrdersTable';

type Props = {
    data: string;
    marginLeft?: string;
    marginRight?: string;
}

const CopyToClipboard = ({ data, marginLeft = '0px', marginRight = '0px' }: Props) => (
  <CopyOutlined onClick={() => copyClipBoard(data)} style={{ color: '#1890ff', marginLeft, marginRight }} />
);

export default CopyToClipboard;
