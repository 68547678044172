import { useEffect, useState } from 'react';

import {
  SelectProps, InputProps, Card, Input, Select, Tag,
} from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import { transactionPatch } from 'configs';
import useRequest from 'hooks/useRequest';
import { getStatusOrder, stateOptions } from 'pages/OrdersTable';
import CopyToClipboard from 'components/CopyToClipboard';

type Props = {
    transaction: any;
    orderType: string;
    updateWhenChange: any;
    prevTransState?: string;
    disableAmonut?: boolean;
    disableEdit: boolean;
    isExpired?: boolean;
}

const FiatTransaction = ({
  transaction, orderType, updateWhenChange, prevTransState, disableAmonut, disableEdit = false, isExpired,
}: Props) => {
  const isBank = transaction?.paymentMethodEnum === 'WIRE_TRANSFER';

  const isPrevReady = prevTransState === 'DONE';

  const {
    fetch, isSuccess, isLoading,
  } = useRequest(transactionPatch);

  const [values, setValues] = useState<any>({
    transactionNote: transaction?.transNotes,
    transState: transaction?.transState,
    orderType,
    transType: transaction?.transactionType,
    amount: transaction?.amount,
  });

  const updateTransaction = () => {
    const body = {
      ...values, transState: values.transState === 'PENDING' ? 'INPROGRESS' : values.transState,
    };
    if (transaction?.transState === 'DONE' || isExpired || transaction?.transState === 'REJECTED') body.transState = null;
    fetch(body, {}, `/${transaction?.transactionId}`);
  };

  const statusHandler = (value: SelectProps) => {
    setValues({ ...values, transState: value });
  };

  const noteHandler = (value: InputProps) => {
    setValues({ ...values, transactionNote: value });
  };

  const noteAmount = (value: InputProps) => {
    setValues({ ...values, amount: value });
  };

  const BankAccount = ({ acc }: any) => {
    const { accountNick, bankAccount, bankBIC, bankName } = acc;
    return (
      <div className="accBox">
        <div className="accountData flexRow">
          <span className="accountData_title">Имя аккаунта</span>
          <span>
            {accountNick}
            <CopyToClipboard data={accountNick} marginLeft="5px" />
          </span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Номер счета</span>
          <span>
            {bankAccount}
            <CopyToClipboard data={bankAccount} marginLeft="5px" />
          </span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">БИК Банка</span>
          <span>
            {bankBIC}
            <CopyToClipboard data={bankBIC} marginLeft="5px" />
          </span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Банк</span>
          <span>
            {bankName}
            <CopyToClipboard data={bankName} marginLeft="5px" />
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!isSuccess) return;
    updateWhenChange();
  }, [isSuccess, updateWhenChange]);

  return (
    <Card
      title={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Денежный перевод (
          {transaction.currency}
          )
          {getStatusOrder(transaction.transState)}
        </div>
      )}
    >
      <div className="inpage">
        <div className="accountData flexRow">
          <span className="accountData_title">Номер транзакции</span>
          <span>{transaction.transactionNumber}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Дата создания</span>
          <span>{transaction.createDate}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Изменено</span>
          <span>{transaction.updateDate || '---'}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Сотрудник</span>
          <span>{transaction.employeeName || '---'}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Сумма</span>
          <span>
            <Input
              value={values.amount}
              onChange={(e: any) => noteAmount(e.target.value)}
              style={{ textAlign: 'end', border: 'none', width: '200px' }}
              disabled={disableAmonut || disableEdit || transaction.transState === 'DONE' || transaction.transState === 'REJECTED' || isExpired}
            />
          </span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Метод оплаты</span>
          <span>{isBank ? 'Банковский перевод' : 'Платежный сервис'}</span>
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">{isBank ? 'Банковский аккаунт' : 'Платежный сервис'}</span>
          {!isBank ? <span>{transaction.providerName}</span> : <Tag color={!transaction.userAccount.active ? 'error' : 'success'}>{transaction.userAccount.active ? 'Активный' : 'Не активный'}</Tag>}
        </div>
        {isBank && (
        <BankAccount acc={transaction.userAccount} />
        )}
        <div className="accountData flexRow">
          <span className="accountData_title">Статус платежа</span>
          <Select
            options={stateOptions.filter((el: any) => (el.value === 'EXPIRED' ? false : transaction.transState === 'INPROGRESS' ? el.value !== 'PENDING' : true))}
            placeholder=""
            onChange={statusHandler}
            disabled={['DONE', 'REJECTED', 'EXPIRED'].includes(transaction.transState) || !isPrevReady || disableEdit || isExpired}
            value={values.transState}
            style={{ width: '200px', textAlign: 'end' }}
          />
        </div>
        <div className="accountData flexRow">
          <span className="accountData_title">Примечание транзакции</span>
          <Input.TextArea placeholder="" onChange={(e: any) => noteHandler(e.target.value)} value={values.transactionNote} style={{ width: '200px' }} disabled={disableEdit} />
        </div>
        {!disableEdit
          && (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <ButtonAnt loading={isLoading} type="primary" onClick={updateTransaction}>Сохранить</ButtonAnt>
          </div>
          )}
      </div>
    </Card>
  );
};

export default FiatTransaction;
