/* eslint-disable max-len */
import {
  computed, decorate, observable, toJS,
} from 'mobx';
import { parseJwt } from 'utils';
import LocalStorage from 'utils/LocalStorage';

export type ILang = 'RU' | 'EN'

export const MOBILE_POINT_MENU = 768;
export const MOBILE_POINT_CONTENT = 414;

export type VerificationStatus = 'PENDING' | 'INPROGRESS' | 'VERIFIED' | 'REJECTED' | 'NEW' | null;

export type Verification = {
  creationDate: string;
  images: any[];
  notes: string;
  updateDate: string;
  verificationStateEnum: VerificationStatus;
}

export type PaymentServicesType = {
  active: boolean;
  amountLowerBound: number;
  amountUpperBound: number;
  id: number;
  note: string;
  providerName: string;
  providerParams: string;
  providerUrl: string;
}

export type NetworksType = {
    confirmationCount: number;
    id: number;
    networkName: string;
    networkURL: string;
    ownerContractAddress: string;
    tokenContractAddress: string;
    treasuryContractAddress: string;
}

export type AccountsType = {
  accountNick: string;
  active: boolean;
  bankAccount: string;
  bankBIC: string;
  bankName: string;
  id: number;
  isDefault: boolean;
}

export type UData = {
  userName: string;
}

export type MetaMasType = {
  address: string | null;
  balance: number;
  allowance: number;
}

export type DictionariesType = {
  companyAccounts: AccountsType[];
  paymentProviders: PaymentServicesType[];
  supportedNetworks: NetworksType[];
}

class Store {
    screenWidth: number = window.innerWidth;

    userData: UData = LocalStorage.get('user') || {} as UData;

    jwtAccess: string = LocalStorage.get('a') || ''

    permissionsProxy: any = LocalStorage.get('p') || []

    get permissions() {
      return toJS(this.permissionsProxy);
    }

    jwtRefresh: string = LocalStorage.get('r') || ''

    get isAuth() {
      if (Object.keys(this.userData).length) {
        return true;
      }
      return false;
    }

    isAccess = (perm: string) => this.permissions.includes(perm)

    setUser = (member: string) => {
      this.userData = {
        userName: member,
      };
      LocalStorage.set('user', { userName: member });
    }

    setScreenWidth = (size: number) => {
      this.screenWidth = size;
    }

    updateToken = (token: string, type: 'access' | 'refresh') => {
      if (type === 'access') {
        this.jwtAccess = token;
        this.permissionsProxy = parseJwt(token)?.permissions;
        LocalStorage.set('p', parseJwt(token)?.permissions);
        LocalStorage.set('a', token);
      } else {
        this.jwtRefresh = token;
        LocalStorage.set('r', token);
      }
    }

    logout = () => {
      LocalStorage.remove('a');
      LocalStorage.remove('r');
      LocalStorage.remove('user');
      LocalStorage.remove('p');
      this.userData = {} as UData;
    }
}

decorate(Store, {
  userData: observable,
  screenWidth: observable,
  isAuth: computed,
  jwtAccess: observable,
  jwtRefresh: observable,
  permissions: computed,
  permissionsProxy: observable,
});

const store = new Store();

export default store;
