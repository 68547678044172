import { useState, useEffect } from 'react';

import { EditOutlined, FileAddOutlined, CopyOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import {
  Table, PageHeader, Tag, Checkbox, Form, Modal, Button,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';
import useRequest from 'hooks/useRequest';
import { useForm } from 'antd/lib/form/Form';
import TableFooter from 'components/Table/TableFooter';
import { useMount } from 'hooks';
import { supportedNetworksGet, supportedNetworksSet, supportedNetworksEdit } from 'configs';
import { fixAddresLength } from 'utils';

import { copyClipBoard } from './OrdersTable';

const SupportedNetworks = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, edit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const [form] = useForm();
  const {
    fetch, isLoading, requestResult,
  } = useRequest(supportedNetworksGet);

  const {
    fetch: setFetch, isSuccess: isSuccessSet, isLoading: isLoadingSet, dropState: dsSet,
  } = useRequest(supportedNetworksSet);

  const {
    fetch: editFetch, isSuccess: isSuccessEdit, isLoading: isLoadingEdit, dropState: dsEdit,
  } = useRequest(supportedNetworksEdit);

  useMount(() => { fetch(); });

  const toogle = (reset: boolean = false) => {
    dsEdit();
    dsSet();
    if (reset) form.resetFields();
    if (isEdit && isOpen) edit(false);
    setIsOpen(!isOpen);
  };

  const setEditData = (data: any) => {
    edit(true);
    form.setFieldsValue(data);
    toogle();
  };

  const createaymentProvider = () => {
    const values = form.getFieldsValue();
    setFetch({
      ...values,
    });
  };

  const editPaymentProvider = () => {
    const values = form.getFieldsValue();
    const { id } = values;
    delete values.id;
    editFetch({
      ...values,
    }, {}, `/${id}`);
  };

  useEffect(() => {
    if (isSuccessEdit || isSuccessSet) {
      toogle(true);
      fetch({}, {
        perPage,
        page: page - 1,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEdit, isSuccessSet]);

  const COLUMN = [
    {
      title: 'Имя сети',
      dataIndex: 'networkName',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Ссылка',
      dataIndex: 'networkURL',
      width: 150,
      minWidth: 150,
    },
    {
      title: 'Идентификатор',
      dataIndex: 'internalName',
      width: 150,
      minWidth: 150,
    },
    {
      title: 'Нижний порог',
      dataIndex: 'amountLowerBound',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Верхний порог',
      dataIndex: 'amountUpperBound',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Номер блока контракта',
      dataIndex: 'contractBlock',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Адрес контракта / Казначейский аккаунт',
      dataIndex: 'ownerContractAddress',
      width: 100,
      minWidth: 100,
      render: (row: any) => (row?.[0] ? (
        <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://bscscan.com/address/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row || '')}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: 'Адрес токена',
      elipces: true,
      dataIndex: 'tokenContractAddress',
      width: 100,
      minWidth: 100,
      render: (row: any) => (row?.[0] ? (
        <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://bscscan.com/address/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row || '')}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: 'Адрес Операционного аккаунта',
      dataIndex: 'treasuryContractAddress',
      width: 100,
      minWidth: 100,
      render: (row: any) => (row?.[0] ? (
        <div style={{ display: 'flex', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://bscscan.com/address/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row || '')}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      width: 100,
      minWidth: 100,
      render: (row: any) => <Tag color={row ? 'success' : 'error'}>{row ? 'Активный' : 'Не активный'}</Tag>,
    },
    {
      title: ' ',
      dataIndex: 'isActive',
      width: 50,
      minWidth: 50,
      render: (_: any, rows: any) => <span onClick={() => setEditData(rows)} style={{ cursor: 'pointer' }}><EditOutlined /></span>,
    },
  ];

  return (
    <>
      <Modal
        visible={isOpen}
        title={isEdit ? 'Редактировать сеть' : 'Добавить сеть'}
        style={{ top: '80px' }}
        width="80vw"
        zIndex={901}
        footer={[
          <ButtonAnt key={0} onClick={() => toogle(true)}>Отмена</ButtonAnt>,
          <ButtonAnt
            key={1}
            htmlType="submit"
            type="primary"
            form="form"
            onClick={isEdit ? editPaymentProvider : createaymentProvider}
            loading={isLoadingSet || isLoadingEdit}
          >
            {isEdit ? 'Сохранить' : 'Создать'}

          </ButtonAnt>]}
        onCancel={() => toogle(true)}
      >
        <Form
          name="basic"
          id="form"
          form={form}
          autoComplete="off"
          initialValues={{ isActive: false, confirmationCount: 8 }}
          style={{
            width: '100%', display: 'flex', marginBottom: '0px', flexDirection: 'row', flexWrap: 'wrap',
          }}
        >
          <div className="formContainer">
            {isEdit && (
            <InputItem
              label="id"
              name="id"
          // placeholder="Введите имя"
              maxLength={250}
              required
              disabled
            />
            )}
            <InputItem
              label="Имя"
              name="networkName"
              placeholder="Введите имя"
              maxLength={250}
              required
            />
            <InputItem
              label="Ссылка на сеть"
              name="networkURL"
              placeholder="Введите ссылку"
              maxLength={250}
              required
            />
            <InputItem
              label="Идентификатор"
              name="internalName"
              placeholder="Введите ссылку"
              maxLength={250}
              required
            />
            <InputItem
              label="Количество подтверждений"
              name="confirmationCount"
              placeholder="Введите количество"
              maxLength={250}
              required
            />
            <InputItem
              label="Минимальная сумма"
              name="amountLowerBound"
              placeholder="Введите сумму"
              maxLength={250}
              required
            />
            <InputItem
              label="Максимальная сумма"
              name="amountUpperBound"
              placeholder="Введите сумму"
              maxLength={250}
              required
            />
            <InputItem
              label="Адрес контракта / Казначейский аккаунт"
              name="ownerContractAddress"
              placeholder="Введите адрес"
              maxLength={250}
              required
            />
            <InputItem
              label="Адрес токена"
              name="tokenContractAddress"
              placeholder="Введите адрес"
              maxLength={250}
              required
            />
            <InputItem
              label="Адрес Операционного аккаунта"
              name="treasuryContractAddress"
              placeholder="Введите адрес"
              maxLength={250}
              required
            />
            <InputItem
              label="Комментарий"
              name="note"
              placeholder="Введите комментарий"
              maxLength={500}
              multiline
            />
            <InputItem
              label="Номер блока контракта"
              name="contractBlock"
              placeholder="Введите номер"
              maxLength={250}
              required
            />

          </div>
          <div className="formLine">
            <Form.Item
              name="isActive"
              valuePropName="checked"
            >
              <Checkbox defaultChecked={false}>
                Активный
              </Checkbox>
            </Form.Item>
          </div>

        </Form>
      </Modal>
      <PageHeader
        title="Доступные сети"
        extra={[
          <Button key="1" type="primary" icon={<FileAddOutlined />} onClick={() => setIsOpen(true)}>Добавить сеть</Button>,
        ]}
      />
      <Content>
        <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ width: '100%' }} size="small" loading={isLoading} pagination={false} />
        {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}
      </Content>
    </>
  );
};

export default observer(SupportedNetworks);
