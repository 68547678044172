import { useState, useEffect } from 'react';

import { EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import {
  Table, PageHeader, Tag, Checkbox, Form, Modal, Button,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { caEdit, caGet, caSet } from 'configs';
import { useForm } from 'antd/lib/form/Form';
import { useMount } from 'hooks';
import useRequest from 'hooks/useRequest';
import TableFooter from 'components/Table/TableFooter';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';

const BankAccounts = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, edit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const [form] = useForm();
  const {
    fetch, isLoading, requestResult,
  } = useRequest(caGet);

  const {
    fetch: setFetch, isSuccess: isSuccessSet, isLoading: isLoadingSet, dropState: dsSet,
  } = useRequest(caSet);

  const {
    fetch: editFetch, isSuccess: isSuccessEdit, isLoading: isLoadingEdit, dropState: dsEdit,
  } = useRequest(caEdit);

  useMount(() => { fetch(); });

  const toogle = (reset: boolean = false) => {
    dsEdit();
    dsSet();
    if (reset) form.resetFields();
    if (isEdit && isOpen) edit(false);
    setIsOpen(!isOpen);
  };

  const setEditData = (data: any) => {
    edit(true);
    form.setFieldsValue(data);
    toogle();
  };

  useEffect(() => {
    fetch({}, {
      perPage,
      page: page - 1,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch]);

  useEffect(() => {
    if (page === 1) {
      fetch({}, { perPage, page: 0 });
    }
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const createaymentProvider = () => {
    const values = form.getFieldsValue();
    setFetch({
      ...values,
    });
  };

  const editPaymentProvider = () => {
    const values = form.getFieldsValue();
    const { id } = values;
    delete values.id;
    editFetch({
      ...values,
    }, {}, `/${id}`);
  };

  useEffect(() => {
    if (isSuccessEdit || isSuccessSet) {
      toogle(true);
      fetch({}, {
        perPage,
        page: page - 1,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEdit, isSuccessSet]);
  const COLUMN = [
    {
      title: 'Имя аккаунта',
      dataIndex: 'accountNick',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Банк',
      dataIndex: 'bankName',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'БИК',
      dataIndex: 'bankBIC',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Счет',
      dataIndex: 'bankAccount',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Нижний порог',
      dataIndex: 'amountLowerBound',
      width: 170,
      minWidth: 170,
    },
    {
      title: 'Верхний порог',
      dataIndex: 'amountUpperBound',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Заметка',
      dataIndex: 'note',
      width: 130,
      minWidth: 130,
      // TODO ADD VALUE
      render: (row: any) => (row || '---'),
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      width: 100,
      minWidth: 100,
      render: (row: any) => <Tag color={row ? 'success' : 'error'}>{row ? 'Активный' : 'Не активный'}</Tag>,
    },
    {
      title: ' ',
      dataIndex: 'isActive',
      width: 50,
      minWidth: 50,
      render: (_: any, rows: any) => <span onClick={() => setEditData(rows)} style={{ cursor: 'pointer' }}><EditOutlined /></span>,
    },
  ];

  return (
    <>
      <Modal
        visible={isOpen}
        title={isEdit ? 'Редактировать аккаунт' : 'Добавить аккаунт'}
        style={{ top: '100px' }}
        zIndex={901}
        footer={[
          <ButtonAnt key={0} onClick={() => toogle(true)}>Отмена</ButtonAnt>,
          <ButtonAnt
            key={1}
            htmlType="submit"
            type="primary"
            form="form"
            onClick={isEdit ? editPaymentProvider : createaymentProvider}
            loading={isLoadingSet || isLoadingEdit}
          >
            {isEdit ? 'Сохранить' : 'Создать'}

          </ButtonAnt>]}
        onCancel={() => toogle(true)}
      >
        <Form
          name="basic"
          id="form"
          form={form}
          autoComplete="off"
          initialValues={{ isActive: true }}
          style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
        >
          <div className="signinForm_box">
            {isEdit && (
            <InputItem
              label="id"
              name="id"
          // placeholder="Введите имя"
              maxLength={250}
              required
              disabled
            />
            )}
            <InputItem
              label="Имя"
              name="accountNick"
              placeholder="Введите имя"
              maxLength={250}
              required
            />
            <InputItem
              label="Номер счета"
              name="bankAccount"
              placeholder="Введите номер счета"
              maxLength={250}
              required
            />
            <InputItem
              label="БИК Банка"
              name="bankBIC"
              placeholder="Введите БИК"
              maxLength={250}
              required
            />
            <InputItem
              label="Название банка"
              name="bankName"
              placeholder="Введите название банка"
              maxLength={250}
              required
            />
            <InputItem
              label="Минимальная сумма"
              name="amountLowerBound"
              placeholder="Введите сумму"
              maxLength={250}
              required
            />
            <InputItem
              label="Максимальная сумма"
              name="amountUpperBound"
              placeholder="Введите сумму"
              maxLength={250}
              required
            />
            <InputItem
              label="Комментарий"
              name="note"
              placeholder="Введите комментарий"
              maxLength={500}
              multiline
            />
            <Form.Item
              name="isActive"
              valuePropName="checked"
            >
              <Checkbox defaultChecked={false}>
                Активный
              </Checkbox>
            </Form.Item>
          </div>

        </Form>
      </Modal>
      <PageHeader
        title="Банковские аккаунты"
        extra={[
          <Button key="1" type="primary" icon={<FileAddOutlined />} onClick={() => setIsOpen(true)}>Добавить аккаунт</Button>,
        ]}
      />
      <Content>
        <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ width: '100%' }} size="small" loading={isLoading} pagination={false} />
        {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}
      </Content>
    </>
  );
};

export default observer(BankAccounts);
