import { message } from 'antd';

export const geenStr = () => (Math.random() + 1).toString(36).substring(7);

export function getRandom(length: number) {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
}
const errCodes = [
  {
    id: '100',
    code_name: 'ERROR_VERIFY_CONFIRMED',
    EN: 'Verification confirmed',
    RU: 'Верификация подтверждена',
  },
  {
    id: '101',
    code_name: 'ERROR_VERIFY_COUNT_EXCEED',
    EN: 'The number of verification attempts exceeded four',
    RU: 'Количество попыток верификации превысило четыре',
  },
  {
    id: '102',
    code_name: 'ERROR_USER_ACCOUNT_COUNT_EXCEED',
    EN: 'The number of bank accounts exceeded three',
    RU: 'Количество банковских аккаунтов превысило три',
  },
  {
    id: '103',
    code_name: 'ERROR_USER_NOT_VERIFIED_EMAIL',
    EN: 'Email confirmation did not pass',
    RU: 'Подтверждение электронной почты не было пройдено',
  },
  {
    id: '104',
    code_name: 'ERROR_USER_EMAIL_NOT_EXISTS',
    EN: 'Email not exists',
    RU: 'Электронная почта не существует',
  },
  {
    id: '105',
    code_name: 'ERROR_EMAIL_UNIQUE',
    EN: 'Email already exists in the system',
    RU: 'Электронная почта уже существует в системе',
  },
  {
    id: '106',
    code_name: 'ERROR_EMAIL_FORMAT_ERROR',
    EN: 'Email format is wrong',
    RU: 'Неверный формат электронной почты',
  },
  {
    id: '107',
    code_name: 'ERROR_NOT_EMPTY_DATA',
    EN: 'The submitted data is empty',
    RU: 'Отправленные данные пусты',
  },
  {
    id: '108',
    code_name: 'ERROR_NOT_NULL_DATA',
    EN: 'The submitted data is empty',
    RU: 'Отправленные данные пусты',
  },
  {
    id: '109',
    code_name: 'ERROR_FORMAT',
    EN: 'Wrong format',
    RU: 'Не верный формат',
  },
  {
    id: '110',
    code_name: 'ERROR_PASSPORT_UNIQUE',
    EN: 'Passport number already exists in the system',
    RU: 'Номер паспорта уже существует в системе',
  },
  {
    id: '111',
    code_name: 'ERROR_UNIQUE_TIN',
    EN: 'TIN already exists in the system',
    RU: 'ИНН уже существует в системе',
  },
  {
    id: '112',
    code_name: 'ERROR_USER_MANDATORY_DATA',
    EN: 'The submitted data is incorrect',
    RU: 'Представленные данные некорректны',
  },
  {
    id: '113',
    code_name: 'ERROR_UNIQUE_PSRN',
    EN: 'PSRN already exists in the system',
    RU: 'ОГРН уже существует в системе',
  },
  {
    id: '114',
    code_name: 'ERROR_UNIQUE_COR',
    EN: 'COR already exists in the system',
    RU: 'КПП уже существует в системе',
  },
  {
    id: '115',
    code_name: 'ERROR_UNIQUE_INIPA',
    EN: 'INIPA already exists in the system',
    RU: 'СНИЛС уже существует в системе',
  },
  {
    id: '116',
    code_name: 'ERROR_NULL_DATA',
    EN: 'Data is null',
    RU: 'Не допустимо пустое значение',
  },
  {
    id: '117',
    code_name: 'ERROR_UNIQUE_PHONE',
    EN: 'Phone number already exists in the system',
    RU: 'Номер телефона уже существует в системе',
  },
  {
    id: '119',
    code_name: 'ERROR_LEGAL_AGE',
    EN: 'You must be 18+ to use the system',
    RU: 'Для использования системы вам должно быть 18+',
  },
  {
    id: '120',
    code_name: 'ERROR_DATE_PAST',
    EN: 'The entered date is in the future',
    RU: 'Введенная дата находится в будущем',
  },
  {
    id: '121',
    code_name: 'ERROR_MUST_TRUE',
    EN: 'Mandatory checkboxes must be checked',
    RU: 'Обязательные флажки должны быть отмечены',
  },
  {
    id: '122',
    code_name: 'ERROR_PASSWORD_MISMATCH',
    EN: 'Password and Confirm password до not match',
    RU: 'Пароль и Подтверждение пароля не совпадают',
  },
  {
    id: '123',
    code_name: 'ERROR_PASSWORD_ONE_MISSED',
    EN: 'No Password or Password confirmation',
    RU: 'Пароль или Подтверждение пароля отсутствует',
  },
  {
    id: '125',
    code_name: 'ERROR_PAYMENT_PROVIDER_MISSED',
    EN: 'No Payment service',
    RU: 'Платежный сервис отсутствует',
  },
  {
    id: '127',
    code_name: 'ERROR_PASSWORD_OLD_WRONG',
    EN: 'The old password is incorrect',
    RU: 'Старый пароль неверный',
  },
  {
    id: '128',
    code_name: 'ERROR_TOKEN_WRONG_EXPIRED',
    EN: 'The security token is incorrect or expired',
    RU: 'Маркер безопасности неверен или просрочен',
  },
  {
    id: '129',
    code_name: 'ERROR_LOGIN',
    EN: 'Sign in fails',
    RU: 'Ошибка входа',
  },
  {
    id: '130',
    code_name: 'ERROR_EMPTY_ROLES',
    EN: 'Employee role is empty',
    RU: 'Роль сотрудника пуста',
  },
];

export const getErr = (list: any[]) => {
  for (let i = 0; i < list.length; i++) {
    const geterr = errCodes.find((el: any) => el.id === list[i].message);
    if (geterr) {
      message.error(`${geterr.RU}.`);
    } else {
      message.error('Возникла неизветная ошибка обратитесь к администратору');
    }
  }
};
export const getSimpleErr = (obj: any) => {
  const geterr = errCodes.find((el: any) => el.id === `${obj.errorCode}`);
  if (geterr) {
    message.error(geterr.RU);
  } else {
    message.error('Возникла неизветная ошибка обратитесь к администратору');
  }
};

export const fixAddresLength = (str: string) => {
  if (!str) return '';
  const s = 5;
  const l = str.length - 7;
  let res = '';
  for (let i = 0; i < str.length; i++) {
    if (i < s || i > l) {
      res += str[i];
    } else if (!res.includes('...')) {
      res += '...';
    } else {
      continue;
    }
  }
  return res;
  // 0x06B7ed75E6acF0a9501979aAf74c216b98E36f09 42
};

export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return '';
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};
