import { Route, Redirect } from 'react-router-dom';

interface Props {
    children: JSX.Element;
    auth: boolean;
    path: string;
    key?: any;
    isAccess: boolean;
}

const PrivateRoute = ({ children, auth, path, key, isAccess }: Props) => (
  <Route key={key} path={path} render={() => ((!auth || !isAccess) ? <Redirect to="/signin" /> : children)} />
);
export default PrivateRoute;
