import { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import store from 'store/mainStore';
import useRequest from 'hooks/useRequest';
import ButtonAnt from 'components/ButtonAnt';
import LocalStorage from 'utils/LocalStorage';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { Divider, Form, Input } from 'antd';
// import { PasswordItem } from 'components/forms/InputItem/PasswordItem';
import { signin } from 'configs';
// import InputItem from 'components/forms/InputItem/InputItem';
import { emailRule } from 'components/forms/InputItem/rules';
import ChangePassword from 'components/header/ChangePassword';
import { Box } from 'components/layout';

export type ILogin = {
    email: string;
    password: string;
}
const Auth = () => {
  const { updateToken, setUser } = store;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };

  const {
    fetch, isSuccess, isLoading, requestResult: tokenData,
  } = useRequest(signin);

  const submit = (values: any) => {
    LocalStorage.remove('a');
    LocalStorage.remove('r');
    fetch(values);
  };

  useEffect(() => {
    if (isSuccess && tokenData) {
      updateToken(tokenData.accessToken, 'access');
      updateToken(tokenData.refreshToken, 'refresh');
      setUser(tokenData.userName);
    }
  }, [isSuccess, tokenData, updateToken, setUser]);

  return (
    <>
      <ChangePassword isOpen={isOpen} toogleModal={toogleModal} isReset />
      <div className="logoAuth">
        <Logo height={300} width={300} />
      </div>
      <div className="signinForm">
        <div className="bgImage" />

        <div className="center">
          <h1>Вход</h1>
        </div>
        <Form
          name="loginForm"
          initialValues={{ remember: true }}
          onFinish={submit}
          autoComplete="off"
          style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
        >
          <div className="signinForm_box">
            {/* <InputItem
              name="email"
              placeholder="Введите почту"
              maxLength={250}
              rules={[emailRule]}
              required
            /> */}
            <Form.Item name="email" rules={[emailRule]} required>
              <Input
                bordered={false}
                maxLength={50}
                className="inp"
              />
            </Form.Item>
            <Form.Item name="password" required>

              <div className="inputBox">
                <Input.Password
                  placeholder="Введите пароль"
                  maxLength={50}
                  style={{ outline: 'none' }}
                />
              </div>
            </Form.Item>
            {/*  <PasswordItem
              name="password"
              style={{ height: '50px' }}
              uneditable={false}
              required
              placeholder="Введите пароль"
            /> */}
            <Form.Item>
              <ButtonAnt htmlType="submit" large type="primary" loading={isLoading}>Войти</ButtonAnt>
            </Form.Item>
          </div>

        </Form>
        <Divider />
        <Box direction="row" justify="flex-end">
          <div style={{ display: 'flex', cursor: 'pointer', color: '#1890ff' }} onClick={toogleModal}>Забыли пароль?</div>
        </Box>
      </div>
    </>
  );
};
export default observer(Auth);
