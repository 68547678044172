import {
  FC, useContext, ReactNode, ChangeEvent,
} from 'react';

import { FormItemProps as AntFormItemProps, Form, Typography } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import FieldContext from 'rc-field-form/lib/FieldContext';

import { REQUIRED } from './rules';

const { Text } = Typography;

type Value = string | number

export type FormItemProps = Omit<AntFormItemProps, 'name'> & {
    prefix?: ReactNode;
    uneditable?: boolean;
    noRequiredSymbol?: boolean;
    name: string;
    renderUneditable?: (value?: Value) => ReactNode;
    autoHeight?: boolean;
}

export function defaultUneditable(prefix: ReactNode = '', value: ReactNode) {
  return value !== undefined ? (
    <Text>
      {prefix}
      {value}
    </Text>
  ) : (
    <Text>---</Text>
  );
}

export function defaultGetValueFromEvent(value: any | any[] | ChangeEvent<HTMLInputElement>) {
  if (value?.target) {
    return value.target.value || undefined;
  }

  if (Array.isArray(value)) return value.length ? value : undefined;

  if (value === 0) return value;

  return value || undefined;
}

function defaultRules(isRequired: boolean) {
  return isRequired ? [REQUIRED] : [];
}

export const FormItem: FC<FormItemProps> = ({
  name,
  className,
  children,
  hidden = false,
  prefix,
  uneditable = false,
  rules = [],
  required = false,
  noRequiredSymbol = false,
  // autoHeight = false,
  renderUneditable = value => defaultUneditable(prefix, value),
  getValueFromEvent = defaultGetValueFromEvent,
  ...rest
}) => {
  const { getFieldValue } = useContext(FieldContext);
  const formItemChildren = !uneditable ? children : renderUneditable(getFieldValue(name));

  return (
    <Form.Item
      {...rest}
      name={name}
      rules={[...rules, ...defaultRules(required)]}
      style={{ display: 'flex', width: '100%', maxWidth: '500px' }}
      /* className={clsx(
        styles.form_item,
        uneditable && styles.form_item_uneditable,
        autoHeight && styles.form_item_autoheight,
        className,
      )} */
      hidden={hidden}
      required={noRequiredSymbol ? false : required}
      getValueFromEvent={getValueFromEvent}
    >
      {formItemChildren}
    </Form.Item>
  );
};
